<h1 mat-dialog-title>Tutorials</h1>
<mat-dialog-content class="mat-typography">
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Connecting">
      <h2>Step 1: Enable Plugins in VTS</h2>
      <p>Open your settings<mat-icon aria-hidden="false" aria-label="Example home icon">settings</mat-icon> in VTS and scroll down to the "Vtube Studio Plugins" section in the main menu. Make sure your status is green.</p>
      <p><img class="tutorial-image" src="\assets\Tutorial01.png"></p>
      
      <h2>Step 2: Click CONNECT in Tie Tools</h2>
      <p>Click "CONNECT" on Tie Tools. Make sure the Port is the same as the settings in VTS (see screenshot in last step).</p>
      <p><img class="tutorial-image" src="\assets\Tutorial02.png"></p>
      <p>If this is your first time connecting, a dialog box in VTS will pop up. Click "Allow".</p>
      <p><img class="tutorial-image" src="\assets\Tutorial03.png"></p>

      <h2>Step 3: Confirm You Are Connected</h2>
      <p>Once Connected the "CONNECT" button becomes disabled and you will have the option to "Reload Modle" if you swap your model in VTS.</p>
      <p><img class="tutorial-image" src="\assets\Tutorial04.png"></p>
      <p>**The effect buttons will also be enabled and effect count(s) will be visable too.**</p>
      <p><img class="tutorial-image" src="\assets\Tutorial05.png"></p>
      <p><b>Repeat Step 2 if you are not connected.</b></p>
    </mat-tab>
    <mat-tab label="Need More?">
      <p>Need help with something besides connecting? DM me on Twitter!</p>
      <br>
      <a href="https://twitter.com/Prof_Tiberius"><img class="logo" src="assets/2021 Twitter logo - white.png"> </a>
    </mat-tab>
  </mat-tab-group>
  

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
