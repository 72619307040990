import { Color } from '@angular-material-components/color-picker';
import { Hotkey } from 'vtubestudio';

export class Effect {
    id: number;
    order: number;
    name: string = '{Rename Me!}';
    autoStart: boolean = false;
    delayStart: boolean = false;
    delayStartSec: number = 0;
    selectedType: string = 'None';
    stopSent: boolean = false;
    allModels: boolean = true;
    modelList: string[] = [];   
    //ColorSettings
    setColor: Color[] = [];
    allMeshes: boolean = false;
    excludeMeshes: boolean = false;
    interpolateColors: boolean = true;
    interpolationSteps: number = 100; 
    loopEffect: boolean = false;
    ignoreDisplayLighting = true;
    selectedMeshes: string[] = [];
    alpha: number = 255;
    delay: number = 1;
    resetOnStop: boolean = false;
    //Hotkey
    hotkeyId: string;
    twitchHotKeyTrigger: boolean = false;
    //Movement Settings
    //Spin
    rotations: number = 1;
    spinForever: boolean = false;
    spinSpeed: number = 1;
    clockwise: boolean = true;
    alternate: boolean = false;
    //Twitch Section
    twitchType: string = 'None';
    twitchStop: number = 0;
    twitchResetStop: boolean = false;
    messageContains: boolean = false;
    messageStartsWith: boolean = false;
    allowUsers:boolean = false;
    blockUsers: boolean = false;
    allowedUsers: string[] = [];
    blockedUsers: string[] = [];
    twitchMessage: string = '';
    setColorMessage: boolean = false;
    //bits
    bits: number = 0;
    bitsRange: number = 0;
    bitOption: string = 'Any';
    //Redeems
    redeemTitle: string  = '';
    //subs
    anySub: boolean = true;
    tier1Sub: boolean = false;
    tier2Sub: boolean = false;
    tier3Sub: boolean = false;
    primeSub: boolean = false;
    giftSub: boolean = true;


  
    constructor(id: number) {
      this.id = id;
      let c = new Color(255, 255, 255);
      this.setColor.push(c);
    }
  }