import { Injectable } from '@angular/core';
import { StaticAuthProvider } from '@twurple/auth';
import { PubSubBitsMessage, PubSubClient, PubSubRedemptionMessage, PubSubSubscriptionMessage } from '@twurple/pubsub';
import { ApiClient, HelixUser } from '@twurple/api';


import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChatClient } from '@twurple/chat';
import { AppLogService } from './app-log.service';

@Injectable({
  providedIn: 'root'
})
export class TwitchService {

  private _user = new BehaviorSubject<HelixUser>(null);
  user = this._user.asObservable();

  private _accessToken = new BehaviorSubject<string>('');
  accessToken = this._accessToken.asObservable();

  private _rewards = new BehaviorSubject<string[]>([]);
  rewards = this._rewards.asObservable();

  private _redeemedReward = new BehaviorSubject<PubSubRedemptionMessage>(null);
  redeemedReward = this._redeemedReward.asObservable();

  private _bitsCheered = new BehaviorSubject<PubSubBitsMessage>(null);
  bitsCheered = this._bitsCheered.asObservable();

  private _subRecieved = new BehaviorSubject<PubSubSubscriptionMessage>(null);
  subRecieved = this._subRecieved.asObservable();

  private _chatMessage = new BehaviorSubject<ChatMessage>(null);
  chatMessage = this._chatMessage.asObservable();

  private _connected = new BehaviorSubject<boolean>(false);
  connected = this._connected.asObservable();

  constructor(private appLog: AppLogService) { }

  updateAccessToken(token: string){
    this._accessToken.next(token);
  }

  //token&scope=openid+user:read:email+channel:read:redemptions+bits:read+chat:read+channel:read:subscriptions
  async connectPubSub(accessToken: string) {
    try{
      if(accessToken != '')
      {
        const authProvider = new StaticAuthProvider(environment.twitchClientId, accessToken, ['chat:read', 'chat:edit', 'channel:read:subscriptions', 'bits:read', 'channel:read:redemptions']);
  
        const pubSubClient = new PubSubClient();
        const apiClient = new ApiClient({ authProvider });
        const userId = await pubSubClient.registerUserListener(authProvider);
  
        const user = await apiClient.users.getUserById(userId);
        this._user.next(user);
  
        await pubSubClient.onSubscription(userId, (message: PubSubSubscriptionMessage) =>{
          this._subRecieved.next(message);
        });
  
        await pubSubClient.onRedemption(userId, (message: PubSubRedemptionMessage) => {
          this._redeemedReward.next(message);
        });
  
        await pubSubClient.onBits(userId, (message: PubSubBitsMessage) => {
          this._bitsCheered.next(message);
        });
  
        const chatClient = new ChatClient({ authProvider, channels: [user.name] });
        await chatClient.connect();
  
        chatClient.onMessage((channel, user, message) => {
          this._chatMessage.next({channel, user, message});
        });
  
        this._connected.next(true);
      }
    }
    catch(e){
      this.appLog.addToLog("Error: " + e.message);
      await this.connectPubSub(accessToken);
    }
    
  }

  updateBitsCheered(message: PubSubBitsMessage){
    this._bitsCheered.next(message);
  }

  updateSubRecieved(message: PubSubSubscriptionMessage){
    this._subRecieved.next(message);
  }

  updateRedeemedReward(message: PubSubRedemptionMessage){
    this._redeemedReward.next(message);
  }
}

export class ChatMessage {
  channel: string;
  user: string;
  message: string;
}
