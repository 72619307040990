<div class="content">
  <mat-grid-list cols="4" rowHeight="150px">
    <mat-grid-tile [colspan]="1">
      <div class="save">
        <button type="button" mat-raised-button color="primary" (click)="saveEffects()" [disabled]="!connected">
          <mat-icon>save</mat-icon> Save
        </button>
        <!-- <mat-checkbox [(ngModel)]="autoSave" [disabled]="!connected">Auto Save</mat-checkbox> -->
        <br>
        Last Save : {{lastSaved}}
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <div class="button-row">
        <button type="button" mat-raised-button color="primary" (click)="addEffect()" [disabled]="!connected">
          Add Effect
        </button>
        <button type="button" mat-raised-button color="primary" (click)="reset()" [disabled]="!connected">
          Reset & Stop Effects
        </button>
        <button type="button" mat-raised-button color="primary" (click)="openImport()" [disabled]="!connected">
          <mat-icon>file_upload</mat-icon>Import
        </button>
        <button type="button" mat-raised-button color="primary" (click)="exportEffects()" [disabled]="!connected">
          <mat-icon>file_download</mat-icon>Export
        </button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <div *ngIf="connected">
        Total Effect Count: {{effects.length}}
        <br>
      <mat-checkbox [(ngModel)]="reOrder" [disabled]="!connected">Toggle Reorder</mat-checkbox>
      </div>
      
    </mat-grid-tile>
    <mat-grid-tile [colspan]="4">
      <mat-form-field appearance="standard" [ngStyle]="{'width': '80%'}">
        <mat-label>Effect Name Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="name" #input [disabled]="!connected||reOrder">
      </mat-form-field>
    </mat-grid-tile>

  </mat-grid-list>
 
  
  
  <div *ngIf="connected" fxLayout="row wrap" fxLayoutAlign="start start">
    <div *ngFor="let effect of filteredEffects | sortByOrder" >
      <mat-card>
        <mat-card-content>
          <mat-form-field [ngStyle]="{'width': '70%'}">
            <mat-label>Effect Name</mat-label>
            <input matInput type="text" [(ngModel)]="effect.name">
          </mat-form-field>
          <span *ngIf="!reOrder">
            
            <button mat-icon-button (click)="copyEffect(effect)">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="removeEffect(effect)">
              <mat-icon>delete</mat-icon>
            </button>
          </span>
          <div *ngIf="reOrder">
            {{effect.order}}
            <button mat-icon-button (click)="moveToFront(effect)"><mat-icon>keyboard_double_arrow_left</mat-icon></button>
            <button mat-icon-button (click)="moveLeft(effect)"><mat-icon>keyboard_arrow_left</mat-icon></button>
            <button mat-icon-button (click)="moveRight(effect)"><mat-icon>keyboard_arrow_right</mat-icon></button>
            <button mat-icon-button (click)="moveToBack(effect)"><mat-icon>keyboard_double_arrow_right</mat-icon></button>
          </div>
        </mat-card-content>
      </mat-card>
      
      <app-effects [effect]="effect"></app-effects>     
    </div>
  </div>
</div>