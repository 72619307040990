import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { VtubeStudioAPIService } from '../vtube-studio-api.service';
import { CurrentModel, Plugin } from 'vtubestudio';
import { Effect } from '../effects/effect';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { AppLogService } from '../app-log.service';
import { timer } from 'rxjs';
import { TwitchService } from '../twitch.service';

//4cb4ff

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  connected: boolean = false;
  twitchConnected: boolean = false;
  
  plugin: Plugin;
  currentModel: CurrentModel;

  effects: Effect[] = [];
  filteredEffects: Effect[] = [];

  reOrder: boolean = false;

  lastSaved: string = '';
  autoSave: boolean = false;

  constructor(
    private vtsApi: VtubeStudioAPIService, 
    public dialog: MatDialog,
    private appLog: AppLogService,
    private twitch: TwitchService, 
    @Inject(LOCALE_ID) private locale: string) {
    
   }

  ngOnInit() {
    this.vtsApi.connected.subscribe(conn => {
      this.connected = conn;
      this.effects = JSON.parse(localStorage.getItem('effects')) ?? [];

      this.lastSaved = localStorage.getItem('lastSaved') ?? '';
      this.autoSave = Boolean(localStorage.getItem('autoSave')) ?? false;

      let fixedEffects = [];
      //version changes
      this.effects.forEach(e => {
        fixedEffects.push(Object.assign(new Effect(-1), e));
      });

      this.effects = fixedEffects;

      for (let index = 0; index < this.effects.length; index++) 
      {
        this.effects[index].order = index;
      }
      
      this.effects.forEach(e1 => {
        let foundIds = 0;
        this.effects.forEach(e => {
          if(e.id === e1.id)
            foundIds++;
        });
        if(foundIds > 1){
          e1.id = this.getNextId();
          this.saveEffects();
        }    
      });
      this.effects = this.effects.sort((a,b) => (a.order > b.order) ? 1 : -1);
      this.filteredEffects = this.effects;
    });

    this.vtsApi.plugin.subscribe(p => {
      this.plugin = p;
      
    });

    this.twitch.connected.subscribe(c => {
      this.twitchConnected = c;  
    });

    this.vtsApi.currentModel.subscribe(c => {
      this.currentModel = c;
    });

    const modelCheck = timer(1000, 1000);

    modelCheck.subscribe(async () => {
      try{
        const model = await this.plugin.currentModel();
        if(this.connected && model.vtsModelName != this.currentModel.vtsModelName)
        {
          await this.reset();
          this.vtsApi.updateCurrentModel(model);
          if(this.twitchConnected)
          {
            this.twitch.updateBitsCheered(null);
            this.twitch.updateSubRecieved(null);
            this.twitch.updateRedeemedReward(null);
          }
        }  
      }
      catch(e)
      {
        if(this.connected)
        {
          //await this.vtsApi.authenticate(this.port);
        }
      }
    });
  }

  async reset()
  {
    this.effects.forEach(e => {
      e.stopSent = true;
    });
    const model = await this.plugin.currentModel();
    await model.colorTint({
          r: 255, 
          g: 255, 
          b: 255,
          mixWithSceneLightingColor: 0
    });
  }

  async addEffect()
  {
    this.effects.push(new Effect(this.getNextId()));
  }

  removeEffect(effect: Effect)
  {
    effect.stopSent = true;
    this.effects = this.effects.filter(e => e.id !== effect.id);
    
    this.filteredEffects = this.filteredEffects.filter(e => e.id !== effect.id);
  }

  copyEffect(effect: Effect)
  {
    const effectCopy = <Effect>JSON.parse(JSON.stringify(effect));

    effectCopy.id = this.getNextId();
    effectCopy.name = effectCopy.name + ' (Copy)';
    this.effects.push(effectCopy);
  }

  saveEffects()
  {
    localStorage.setItem("effects", JSON.stringify(this.effects));
    this.lastSaved = formatDate(Date.now(),'long',this.locale);
    localStorage.setItem('lastSaved', this.lastSaved);
    this.appLog.addToLog("Effects Saved");
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filteredEffects = this.effects.filter(e => e.name.toLowerCase().includes(filterValue.toLowerCase()));
    
  }

  exportEffects() {
    var sJson = JSON.stringify(this.effects);
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', "TieToolsEffects_"+ this.currentModel.vtsModelName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  openImport() {
    const dialogRef = this.dialog.open(ImportDialog);

    dialogRef.afterClosed().subscribe(result => {
      let fileReader = new FileReader();
      fileReader.readAsText(result.fileSource)
      fileReader.onloadend = (e) => {
        let result: Effect[] = JSON.parse(fileReader.result.toString());
        let fixedResults = [];
        result.forEach(r => {
          fixedResults.push(Object.assign(new Effect(-1), r));
        });
        this.effects = fixedResults;
      };
    });
  }

  moveToFront(effect: Effect) {

    this.filteredEffects = this.filteredEffects.filter(e => e.id != effect.id);

    this.effects.unshift(effect);

    for(let i = 0; i < this.effects.length; i++)
    {
      this.effects[i].order = i;
    }
  }

  moveToBack(effect: Effect) {
    this.effects = this.effects.filter(e => e.id != effect.id).concat(this.effects.filter(e => e.id === effect.id));

    for(let i = 0; i < this.effects.length; i++)
    {
      this.effects[i].order = i;
    }
  }

  moveLeft(effect: Effect) {
    const index = this.effects.findIndex(e => e.id === effect.id);
    if(index != 0)
    {
      const left = index - 1;
      [this.effects[left], this.effects[index]] = [this.effects[index], this.effects[left]];

      for(let i = 0; i < this.effects.length; i++)
      {
        this.effects[i].order = i;
      }
    }
  }

  moveRight(effect: Effect) {
    const index = this.effects.findIndex(e => e.id === effect.id);
    if(index != this.effects.length - 1)
    {
      const right = index + 1;
      [this.effects[index], this.effects[right]] = [this.effects[right], this.effects[index]];

      for(let i = 0; i < this.effects.length; i++)
      {
        this.effects[i].order = i;
      }
    }
  }

  private getNextId(): number {

    if(this.effects.length === 0)
      return 0;

    const ids = <number[]>[]; 
    this.effects.forEach(e => {
      ids.push(e.id);
    });
    const max = Math.max(...ids);
    return  max + 1; 
  }
}

@Component({
  selector: 'import-dialog',
  templateUrl: 'import-dialog.html',
  styleUrls: ['./home.component.scss']
})
export class ImportDialog {

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  effects: Effect[] = []; 
    
  constructor(public dialogRef: MatDialogRef<ImportDialog>) { }
      
  get f(){
    return this.myForm.controls;
  }
     
  onFileChange(event:any) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  import(){
    const file =  this.myForm.getRawValue();
    this.effects = JSON.parse(file);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}