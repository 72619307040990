<h1 mat-dialog-title>Patch Notes</h1>
<mat-dialog-content class="mat-typography">
  <p>
    2.0.0 - The Big Twitch Update
      <br>
        - Added Twitch Integration! Channel Points, Subs, and Bits can activate effects. Messages with Subs/Bits and Channel Point Input can be read as well.
      <br>
        - Added Auto Start! Effects can start automaticaly when you connect to VTube Studio. Check the box next to "Reset Local Meshes".
      <br>
        - Updated Code for starting/stopping effects for a more instant response.
  </p>
  <p>
    2.0.0 - The Big Twitch Update
      <br>
        - Added Twitch Integration! Channel Points, Subs, and Bits can activate effects. Messages with Subs/Bits and Channel Point Input can be read as well.
      <br>
        - Added Auto Start! Effects can start automaticaly when you connect to VTube Studio. Check the box next to "Reset Local Meshes".
      <br>
        - Updated Code for starting/stopping effects for a more instant response.
      <br>
        - Added Interpolation Steps. To Make colors transition faster or slower change the value to be between 2 and 1000. Default is 100. 
  </p>
  <p>
    2.1.0 - SPIN! 
      <br>
      - Added the spin effect! Clockwise, Counter-Clockwise, and Alternating. Spin forever or set your rotations. Works with Twitch intergration. 
      <br>
      - Fixed bug where interpolated color effects were not working if created before version 2.0.0. If "Interpolation Steps" is blank, the effect will not work. A default value of 100 will be set if blank. 
  </p>
  <p>
    2.2.0 - Hotkeys
      <br>
      - Hotkeys can now be activated from Tie Tools! Twitch integration works as well.
      <br>
      - If the Hotkey is to change a model, Tie Tools will automaticly swap the effects to the correct model.
      <br>
      - Fixed bug where Random Color would rotate a model with new effect created in version 2.1.0. 
  </p>
  <p>
    2.2.1 - Bug Fix
      <br>
      - Fixed a bug where if Set Color was saved as null, it would break the application. 
  </p>
  <p>
    2.2.2 - Added Log File
      <br>
      - The "Log" Button has been added at the top to track what is going on in Tie Tools. If an error occurs it can be exported and sent to Prof Tiberius for review. 
  </p>
  <p>
    2.3.0 - Twitch Chat Update
      <br>
        - Added Twitch Chat Integration!
      <br>
        - Added Blocked and Allowed Users for Twitch Redeems.
      <br>
        - Added Re-Ordering toggle for Effects, "Toggle Reorder". 
      <br>
        - Added Model Selector to effects, you can now choose if an effect works on all models or specific ones.
      <br>
        - Fixed Bug where clicking on a mesh would not remove it from the list. 
  </p>
  <p>
    2.3.1 - Bug Fixes
      <br>
        - Fixed a bug where Twitch Chat Integration, would slow down the application the more effects you had. 
      <br>
        - Fixed a bug where importing effects would not be upgraded to the most recent version. 
      <br>
        - Removed some vestigial dev code.  
  </p>
  <p>
    2.3.2 - Bug Fixes
      <br>
        - Fixed major bug where filtering effects would delete them.
      <br>
        - Fixed bug where Twitch Integration would bypass the selected model list. 
  </p>
  <p>
    2.3.3 - Effect Management UI 
      <br>
        - Cleaned up the UI for Managing Effects. Saving now has the save icon button and shows the last time the effects were saved. 
  </p>
  <p>
    2.3.4 - Bug Fix
      <br>
        - Fixed a bug where effects could be run multiple times on Auto Start or by clicking the start button too mnay
  </p>
  <p>
    2.3.5 - Bug Fix
      <br>
        - Fixed a bug where Twitch Integration would cause effects to loop forever.  
  </p>
  <p>
    2.3.6 - Bug Fix
      <br>
        - Fixed a bug where Twitch Integration would cause effects to trigger spin effects on all models instead of the selected ones.    
  </p>
  <p>
    2.3.7 - Model Swap Fix
      <br>
        - When a model is swapped outside of Tie Tools, it will auto-reload the model and auto start any associated effects.
      <br>
        - Fixed Bug with imported effects not loading properly. 
      <br>
        - Minor UI improvements.
  </p>
  <br>
  <mat-divider></mat-divider>
  <br>
  <p>
    1.1.0
      <br>
      - Added "Exclude Meshes?" toggle. This will run the effect on all meshes, except for the ones selected. 
      <br>
      - Added "Reset Local Meshes" button. This will reset only the meshes selected back to their default state. If "All Meshes?" is toggled, all meshes are reset. If "Exclude Meshes?" is toggled, all meshes except for the selected are reset.
      <br>
      - Added "Ignore Screen Display" toggle. Disabling it will blend the color change with the “Display Lighting” feature in Vtube Studio. 
      <br>
      - Collapsed Meshes and Settings on Effect Cards to take less screen space.
      <br>
      - Added Import/Export buttons. Importing will replace ALL your effects with the backup.
      <br>
      - Added Support buttons. Tie Tools is FREE but support is appreciated and will go towards making the app better for everyone!     
  </p>
  <p>
    1.1.1
      <br>
      - Fixed bug with duplicate ids made from older version. This would cause multiple effects to be deleted if they were copied.
      <br>
      - Made it so that "Ignore Screen Display" is toggled by default. This will prevent effects from half working. Effect made before 1.1.0 will have the setting toggled.
  </p>
  <br>
  <mat-divider></mat-divider>
  <br>
  <p>
    1.0.0 - Initial Release.
  </p>
  <p>
    1.0.1 - Fixed bug where meshes of similar names were being tinted along with the intended mesh. (ex. Selecting 'ArtMesh8' would also tint 'ArtMesh80')
  </p>
  <p>
    1.0.2 - Added Import/Export. Importing will replace all effects with the backup file. Fixed bug with Reset all button. 
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
