<mat-card>
  <div class="color-show" *ngIf="isRunning" [ngStyle]="{'background': bannerColor}"></div>
  <div class="color-show" *ngIf="!isRunning" [ngStyle]="{'background': '#393939'}"></div>
  <mat-card-actions align="end">
    <h2>Running: {{isRunning}}</h2>
    <span class="spacer"></span>
    <button mat-flat-button color="primary"(click)="start()" [disabled]="!canStartValue">START</button>
    <button mat-flat-button color="warn" (click)="stop()">STOP</button>
  </mat-card-actions>
    <mat-card-content>
        <button *ngIf="effect.selectedType === 'RandomColor' || effect.selectedType === 'SetColor'"
         mat-flat-button color="warn" (click)="resetMeshes()">Reset Local Meshes</button>
        <mat-accordion>
          <!--Settings--> 
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Settings
              </mat-panel-title>
              <mat-panel-description>
                Configure Effect
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-checkbox [(ngModel)]="effect.autoStart">Auto Start</mat-checkbox>
            <mat-checkbox [(ngModel)]="effect.delayStart">Delay Start</mat-checkbox>
            <br>
            <mat-form-field appearance="outline" *ngIf="effect.delayStart">
              <mat-label>Delay Start (Sec)</mat-label>
              <input matInput type="number" [disabled]="isRunning" [(ngModel)]="effect.delayStartSec" min="1">
              <mat-error *ngIf="effect.alpha < 1"></mat-error>
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline">
              <mat-label>Effect Type</mat-label>
              <mat-select [(value)]="effect.selectedType" [disabled]="isRunning">
                  <mat-option *ngFor="let type of effectTypes" [value]="type">
                  {{type}}
                  </mat-option>
              </mat-select>
              </mat-form-field>
              <!--Color Settings-->
              <span *ngIf="effect.selectedType === 'RandomColor' || effect.selectedType === 'SetColor'">
                <mat-form-field appearance="outline" >
                  <mat-label>Alpha</mat-label>
                  <input matInput type="number" [(ngModel)]="effect.alpha" min="0" max="255">
                  <mat-error *ngIf="effect.alpha > 255"></mat-error>
                  <mat-error *ngIf="effect.alpha < 0"></mat-error>
                </mat-form-field>
                
                <div *ngIf="effect.selectedType === 'SetColor'" class="color-list">
                  <button mat-flat-button color="primary" (click)="addColor()" [disabled]="isRunning">
                    Add Color
                  </button>
                  Color Count: {{this.effect.setColor.length}}
                  <div *ngFor="let control of colorControl; index as i">
                    {{i}}
                    <mat-form-field appearance="outline">
                      <mat-label>Color Picker</mat-label>
                      <input matInput [ngxMatColorPicker]="picker" [formControl]="control">
                      <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
                      <ngx-mat-color-picker #picker [touchUi]="touchUi" [color]="colors[i]"></ngx-mat-color-picker>
                  </mat-form-field>
                  <button mat-icon-button color="warn" (click)="deleteColor(control)">
                    <mat-icon>delete</mat-icon>
                  </button>
                  </div>
                </div>             
                <br>
                <mat-checkbox [(ngModel)]="effect.resetOnStop">Reset When Stopped</mat-checkbox>
                <br>
                <mat-checkbox [(ngModel)]="effect.interpolateColors ">Interpolate Colors</mat-checkbox>
                <br>
                <mat-form-field appearance="outline" *ngIf="effect.interpolateColors">
                  <mat-label>Interpolation Steps</mat-label>
                  <input matInput type="number" [disabled]="isRunning" [(ngModel)]="effect.interpolationSteps" min="2" max="1000">
                  <mat-error *ngIf="effect.alpha > 1000"></mat-error>
                  <mat-error *ngIf="effect.alpha < 2"></mat-error>
                </mat-form-field>
                <br>
                <mat-checkbox [(ngModel)]="effect.loopEffect">Loop Effect</mat-checkbox>
                <br>
                <mat-form-field appearance="outline" *ngIf="effect.loopEffect" >
                    <mat-label>Delay(sec)</mat-label>
                    <input matInput type="number" [(ngModel)]="effect.delay" min="0">
                </mat-form-field>
                <br>
                <mat-checkbox [(ngModel)]="effect.ignoreDisplayLighting">Ignore Screen Display</mat-checkbox>
              </span>
              <!--Movement-->
              <span *ngIf="effect.selectedType === 'Spin'">
                <mat-form-field appearance="outline" >
                  <mat-label>Spin Speed</mat-label>
                  <mat-select [(value)]="effect.spinSpeed" [disabled]="isRunning">
                    <mat-option *ngFor="let speed of spinSpeedValues" [value]="speed">
                      {{speed}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline">
                  <mat-label>Rotations</mat-label>
                  <input matInput type="number" [disabled]="isRunning || effect.spinForever" [(ngModel)]="effect.rotations" min="0">
                  <mat-error *ngIf="effect.rotations < 0"></mat-error>
                </mat-form-field>
                <mat-checkbox [(ngModel)]="effect.spinForever" [disabled]="isRunning">Spin Forever</mat-checkbox>
                <br>
                <mat-checkbox [(ngModel)]="effect.clockwise" [disabled]="effect.alternate || isRunning">Clockwise</mat-checkbox>
                <br>
                <mat-checkbox [(ngModel)]="effect.alternate" [disabled]="effect.clockwise || isRunning">Alternate</mat-checkbox>
                <br>
              </span>
              <!--Hotkey-->
              <span *ngIf="effect.selectedType === 'Hotkey'">
                <mat-form-field appearance="outline">
                  <mat-label></mat-label>
                  <mat-select [(value)]="effect.hotkeyId" [disabled]="isRunning">
                    <mat-option *ngFor="let hotkey of hotkeys" [value]="hotkey.id">
                      {{hotkey.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
          </mat-expansion-panel> 
          <mat-expansion-panel *ngIf="effect.selectedType === 'RandomColor' || effect.selectedType === 'SetColor'">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Meshes
              </mat-panel-title>
              <mat-panel-description>
                Pick Model Parts
              </mat-panel-description>
            </mat-expansion-panel-header>
              <mat-checkbox [(ngModel)]="effect.allMeshes" [disabled]="effect.excludeMeshes">All Meshes?</mat-checkbox>
              <br>
              <mat-checkbox [(ngModel)]="effect.excludeMeshes" [disabled]="!effect.allMeshes">Exclude Meshes?</mat-checkbox>
              <br>
              <mat-form-field class="chip-list" appearance="outline">
                  <mat-label>Selected Meshes</mat-label>
                  <mat-chip-list #chipMeshList aria-label="Mesh selection" [disabled]="effect.allMeshes && !effect.excludeMeshes">
                    <mat-chip
                      *ngFor="let mesh of effect.selectedMeshes"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="removeMesh(mesh)">
                      {{mesh}}
                      <button matChipRemove *ngIf="removable">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="Add mesh..."
                      #meshInput
                      [formControl]="meshControl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipMeshList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="addMesh($event)">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedMesh($event)">
                    <mat-option *ngFor="let mesh of filteredMeshes | async" [value]="mesh">
                      {{mesh}}
                    </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
          </mat-expansion-panel>
          <!--Model-->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Model 
              </mat-panel-title>
              <mat-panel-description>
                Pick which Models
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-checkbox [(ngModel)]="effect.allModels">All Models</mat-checkbox>
            <br>
            <mat-form-field class="chip-list" appearance="outline">
              <mat-label>Selected Models</mat-label>
              <mat-chip-list #chipModelList aria-label="Model selection" [disabled]="effect.allModels">
                <mat-chip
                  *ngFor="let model of effect.modelList"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeModel(model)">
                  {{model}}
                  <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input
                  placeholder="Add model..."
                  #modelInput
                  [formControl]="modelControl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipModelList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addModel($event)">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedModel($event)">
                <mat-option *ngFor="let model of filteredModels | async" [value]="model">
                  {{model}}
                </mat-option>
              </mat-autocomplete>
          </mat-form-field>
          </mat-expansion-panel>
          <!--Twitch-->
          <mat-expansion-panel *ngIf="twitchConnected">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Twitch
              </mat-panel-title>
              <mat-panel-description>
                Integration Options
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline" >
            <mat-label>Trigger Option</mat-label>
            <mat-select [(value)]="effect.twitchType" [disabled]="isRunning" (selectionChange)="twitchIntegration()">
                <mat-option *ngFor="let type of twitchOptions" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="effect.twitchType!='None'">
              <mat-form-field appearance="outline" *ngIf="effect.twitchType==='CP Redeem'">
                <mat-label>Redeem Title</mat-label>
                <input matInput type="text" [(ngModel)]="effect.redeemTitle">
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="effect.twitchType==='Bits'">
                <mat-label>Bit Option</mat-label>
                <mat-select [(value)]="effect.bitOption" [disabled]="isRunning">
                  <mat-option *ngFor="let option of bitOptions" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="effect.twitchType==='Bits' && effect.bitOption != 'Any' && effect.bitOption != null" >
                <mat-form-field appearance="outline">
                  <mat-label>Bits</mat-label>
                  <input matInput type="number" [(ngModel)]="effect.bits" min="0">
                </mat-form-field>
                <span *ngIf="effect.bitOption === 'Range'">
                  -
                  <mat-form-field appearance="outline">
                    <mat-label>Bits</mat-label>
                    <input matInput type="number" [(ngModel)]="effect.bitsRange" min="0" [disabled]="!effect.bitOption === 'Range'">
                  </mat-form-field>
                </span>
              </div>
              <div *ngIf="effect.twitchType==='Sub'">
                <mat-checkbox [(ngModel)]="effect.anySub">Any Sub</mat-checkbox>
                <br>
                <mat-checkbox [disabled]="effect.anySub" [(ngModel)]="effect.giftSub">Gift Sub</mat-checkbox>
                <br>
                <mat-checkbox [disabled]="effect.anySub" [(ngModel)]="effect.tier1Sub">Tier1 Sub</mat-checkbox>
                <mat-checkbox [disabled]="effect.anySub" [(ngModel)]="effect.tier2Sub">Tier2 Sub</mat-checkbox>
                <mat-checkbox [disabled]="effect.anySub" [(ngModel)]="effect.tier3Sub">Tier3 Sub</mat-checkbox>             
              </div>
              <br>
              <mat-checkbox [disabled]="effect.setColorMessage || effect.messageStartsWith" [(ngModel)]="effect.messageContains">Message Contains</mat-checkbox>
              <mat-checkbox [disabled]="effect.setColorMessage || effect.messageContains" [(ngModel)]="effect.messageStartsWith">Message Starts With</mat-checkbox>
              <div *ngIf="effect.messageContains || effect.messageStartsWith">
                <mat-form-field appearance="outline">
                  <mat-label>Twitch Message</mat-label>
                  <input matInput type="text" [(ngModel)]="effect.twitchMessage">
                </mat-form-field>
              </div>
              <br>
              <mat-checkbox [disabled]="effect.messageContains" *ngIf="effect.selectedType === 'SetColor'" [(ngModel)]="effect.setColorMessage">Set Color From Message(Hex Code Only!)</mat-checkbox>
              <br>
              <mat-checkbox [(ngModel)]="effect.allowUsers">Allow Users</mat-checkbox>
              <mat-form-field class="chip-list" appearance="outline" *ngIf="effect.allowUsers">
                <mat-label>Allowed Users</mat-label>
                <mat-chip-list #allowedUserList aria-label="Allowed Users">
                  <mat-chip *ngFor="let user of effect.allowedUsers" (removed)="removeAllowedUser(user)">
                    {{user}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input placeholder="New user..."
                         [matChipInputFor]="allowedUserList"
                         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                         (matChipInputTokenEnd)="addAllowedUser($event)">
                </mat-chip-list>
              </mat-form-field>
              <br>
              <mat-checkbox [(ngModel)]="effect.blockUsers">Block Users</mat-checkbox>
              <mat-form-field class="chip-list" appearance="outline" *ngIf="effect.blockUsers">
                <mat-label>Blocked Users</mat-label>
                <mat-chip-list #blockedUserList aria-label="Blocked Users">
                  <mat-chip *ngFor="let user of effect.blockedUsers" (removed)="removeBlockedUser(user)">
                    {{user}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input placeholder="New user..."
                         [matChipInputFor]="blockedUserList"
                         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                         (matChipInputTokenEnd)="addBlockedUser($event)">
                </mat-chip-list>
              </mat-form-field>
              <br>
              <mat-form-field appearance="outline">
                <mat-label>Stop(sec)</mat-label>
                <input matInput type="number" [(ngModel)]="effect.twitchStop" min="0">
            </mat-form-field>
            <mat-checkbox *ngIf="effect.selectedType === 'RandomColor' || effect.selectedType === 'SetColor'" [(ngModel)]="effect.twitchResetStop">Reset After Stop</mat-checkbox>
            <mat-checkbox *ngIf="effect.selectedType === 'Hotkey'" [(ngModel)]="effect.twitchHotKeyTrigger">Trigger Again After Stop</mat-checkbox>
            </span>
            
          </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
    
    <mat-card-footer>
        <mat-progress-bar *ngIf="isRunning" mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
</mat-card>