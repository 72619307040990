<mat-toolbar class="main-mat-toolbar">
    <img class="logo" src="assets/TieLogo.png">  Tools - V2.3.7
    <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <span class="spacer"></span>
    <button mat-flat-button color="primary" (click)="openLog()">
      Log
    </button>
    <button mat-flat-button color="primary" (click)="openTutorial()">
      Tutorial
    </button>
    <button mat-flat-button color="primary" (click)="openPatchNotes()">
      Patch Notes
    </button>   
</mat-toolbar>
<mat-drawer-container class="container">
    <mat-drawer #drawer class="sidenav" mode="side" opened>
      <mat-card>
        <mat-card-title>Connect to Twitch (Optional)</mat-card-title>
        <mat-card-content>
          <h2>Is Twitch connected? <b>{{twitchConnected}}</b></h2>
          <p *ngIf="twitchConnected"><b>Current User:</b> {{user.displayName}}</p>
          <button mat-raised-button class="twitch-button" (click)="connectTwitch()" [disabled]="twitchConnected || disableButtons">Connect Twitch</button>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Connect to Vtube Studio</mat-card-title>
        <mat-card-content>
          <h2>Is the plugin connected? <b>{{connected}}</b></h2>
          <p *ngIf="currentModel.vtsModelName !== 'Not Loaded'"><b>Current Model: </b> {{currentModel.vtsModelName}}</p>
          <p *ngIf="currentModel.vtsModelName !== 'Not Loaded'"><button mat-flat-button color="primary" (click)="reloadModel()">Reload Model</button></p>
        </mat-card-content>
        <mat-card-actions>
          <button type="button" mat-flat-button class="vts-button" (click)="setPlugin()" [disabled]="connected || disableButtons">
            Connect VTS
          </button>
          <mat-form-field>
            <mat-label>Port #</mat-label>
            <input matInput type="number" [(ngModel)]="port">
          </mat-form-field>
          
        </mat-card-actions>
      </mat-card>
      <mat-card>
        <mat-card-title>Support!</mat-card-title>
        <mat-card-content>
            <p>
              Tie Tools will always be a site of of FREE plugins/tools for VTubing and Streaming. Any support is greatly appreciated.
            </p>
            <iframe width="fit-content" height="60px" frameBorder="0" [src]="kofiSafe"></iframe>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>Having Issues?</mat-card-title>
        <mat-card-content>
            <p>
                If something isn't working properly or are having issues, DM me on Twitter or join the Discord!. I will get back to you as soon as I'm able. 
            </p>
            <p><img class="signature" src="assets/Tiberius Logo.png"></p>
            <p>
                <a href="https://discord.gg/xSdH2CTABk" target="_blank" rel="noopener noreferrer"><img class="logo" src="assets/Discord-Logo-Wordmark-White.png"> </a>
                <br>
                <a href="https://twitter.com/Prof_Tiberius" target="_blank" rel="noopener noreferrer"><img class="logo" src="assets/2021 Twitter logo - white.png"> </a>
            </p>
        </mat-card-content>
      </mat-card>
    </mat-drawer>
  
    <div>
        <router-outlet></router-outlet>  
    </div>
    

  </mat-drawer-container>
  


<mat-toolbar class="footer">
    <span class="spacer"></span>
    Made by Prof. Tiberius: 
    <a href="https://www.twitch.tv/prof_tiberius" target="_blank" rel="noopener noreferrer"><img class="logo" src="assets/TwitchGlitchWhite.png"> </a>
    <a href="https://twitter.com/Prof_Tiberius" target="_blank" rel="noopener noreferrer"><img class="logo" src="assets/2021 Twitter logo - white.png"> </a>
    <!-- <a href="https://discord.gg/xSdH2CTABk" target="_blank" rel="noopener noreferrer"><img class="logo" src="assets/Discord-Logo-White.png"> </a> -->
    <a href="https://www.youtube.com/channel/UC3Ch-PwJVcpfTdFIGSfZSwQ" target="_blank" rel="noopener noreferrer"><img class="logo" src="assets/yt_icon_mono_dark.png"> </a>
    <a href='https://ko-fi.com/H2H87HQAA' target="_blank" rel="noopener noreferrer"><img class="logo" src="\assets\Ko-fi_Icon_RGBforDarkBg.png"></a>
    <span class="spacer"></span>
</mat-toolbar>



