import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VtubeStudioAPIService } from './vtube-studio-api.service';
import { CurrentModel, Plugin } from 'vtubestudio';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { TwitchService } from './twitch.service';
import { HelixUser } from '@twurple/api';
import { Insights } from './insights.service';
import { AppLogService } from './app-log.service';
import { timer } from 'rxjs';
import { VtubeSudioApiV2Service } from './vtube-sudio-api-v2.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  
})

export class AppComponent {
  title = 'T Vtube Tools';

  port: string;
  connected: boolean = false;
  twitchConnected: boolean = false;
  disableButtons: boolean = true;
  
  plugin: Plugin;
  currentModel: CurrentModel;
  meshes: string[] = [];

  kofi: string = 'kofibutton.html';
  kofiSafe: SafeResourceUrl;

  user: HelixUser;

  tokenData: any = '';
  accessToken: string = '';
  idToken: JSON;
  redirect: string = window.location.href.toString();
  twitchConnectUrl: string = 'https://id.twitch.tv/oauth2/authorize?client_id=k8j8gbuxog0umj4lphwo0oj3x077ic&redirect_uri='+this.redirect+'redirect&response_type=token+id_token&scope=openid+user:read:email+channel:read:redemptions+bits:read+chat:read+chat:edit+channel:read:subscriptions&claims={"id_token":{"email":null,"email_verified":null},"userinfo":{"picture":null}}';
  
  constructor(private vtsApi: VtubeStudioAPIService,
    // private vtsApiv2: VtubeSudioApiV2Service,
    private twitch: TwitchService, 
    public dialog: MatDialog, 
    public sanitizer: DomSanitizer, 
    public httpClient: HttpClient, 
    private insights: Insights,
    private appLog: AppLogService) {
      // vtsApiv2.messages.subscribe(msg =>{
      //   console.log("Response from websocket: " + msg);
      // });
   }

  ngOnInit() {
    
    this.kofiSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.kofi);

    this.port = localStorage.getItem('port') ?? '8001';

    this.vtsApi.connected.subscribe(conn => {
      this.connected = conn;
      if(conn)
      {
        this.insights.trackEvent("VTS Connected");
        this.appLog.addToLog("Vtube Studio Connected")
      }
        
    });

    this.vtsApi.plugin.subscribe(p => {
      this.plugin = p;
      
    });

    this.vtsApi.currentModel.subscribe(c => {
      this.currentModel = c;
    });

    this.vtsApi.meshes.subscribe(m => {
      this.meshes = m;
    });

    this.twitch.accessToken.subscribe(at => {
      this.twitch.connectPubSub(at);
    });

    this.twitch.connected.subscribe(c => {
      this.twitchConnected = c;
      if(c)
      {
        this.insights.trackEvent("Twitch Connected");
        this.appLog.addToLog(this.user.displayName + " Twitch Connected");
      }    
    });

    this.twitch.user.subscribe(u => this.user = u);

    this.enableButton();
    this.appLog.addToLog("Session Started");

  }
  
  async setPlugin() {
    localStorage.setItem('port', this.port);
    await this.vtsApi.authenticate(this.port);
    // this.vtsApiv2.authenticate();
  }

  async enableButton(){
    await new Promise(f => setTimeout(f, 2000));
    this.disableButtons = false;
  }
  async reloadModel() {
    this.vtsApi.updateCurrentModel(await this.plugin.currentModel());
  }

  connectTwitch() {
    window.location.href = this.twitchConnectUrl;  
  }
  
  openTutorial() {
    const dialogRef = this.dialog.open(TutorialDialog);

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  openPatchNotes() {
    const dialogRef = this.dialog.open(PatchNotesDialog);

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  openLog() {
    const dialogRef = this.dialog.open(LogDialog);

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
}

@Component({
  selector: 'tutorial-dialog',
  templateUrl: 'tutorial-dialog.html',
  styleUrls: ['./app.component.scss']
})
export class TutorialDialog {}

@Component({
  selector: 'patchnotes-dialog',
  templateUrl: 'patchnotes-dialog.html',
  styleUrls: ['./app.component.scss']
})
export class PatchNotesDialog {}


@Component({
  selector: 'log-dialog',
  templateUrl: 'log-dialog.html',
  styleUrls: ['./app.component.scss']
})
export class LogDialog {

  content: string = "";
  constructor(private appLog: AppLogService)
  {
    this.appLog.log.subscribe(l => this.content = l);
  }

  exportLog(){
    var element = document.createElement('a');
    element.setAttribute('href', "data:text," + encodeURIComponent(this.content));
    element.setAttribute('download', "TieToolsLogFile");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }
}