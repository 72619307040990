import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class Insights {
    private angularPlugin = new AngularPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: environment.appInsights,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new ErrorHandler()],
                },
            },
        },
    });

    // expose methods that can be used in components and services
    trackEvent(name: string) {
      this.appInsights.trackEvent({name: name});
    }
    trackTrace = this.appInsights.trackTrace;

    constructor(private router: Router) {
        this.appInsights.loadAppInsights();
    }
}