import { Color } from '@angular-material-components/color-picker';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { VtubeStudioAPIService } from '../vtube-studio-api.service';
import { Plugin, CurrentModel, Hotkey } from 'vtubestudio';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ThemePalette } from '@angular/material/core';
import { TwitchService } from '../twitch.service';
import { Effect } from './effect';
import { AppLogService } from '../app-log.service';

@Component({
  selector: 'app-effects',
  templateUrl: './effects.component.html',
  styleUrls: ['./effects.component.scss']
})
export class EffectsComponent implements OnInit {

  @Input() effect: Effect;

  meshes: string[] = [];
  models: string[] = [];

  filteredMeshes: Observable<string[]>;
  filteredModels: Observable<string[]>;

  meshControl = new FormControl();
  modelControl = new FormControl();

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  colorControl: FormControl[] = [];
  colors: ThemePalette[] = [];

  _stopped = new BehaviorSubject<boolean>(true);
  stopped = this._stopped.asObservable();

  _running = new BehaviorSubject<boolean>(false);
  running = this._running.asObservable();

  _canStart = new BehaviorSubject<boolean>(false);
  canStart = this._canStart.asObservable();

  isRunning: boolean = false;
  twitchConnected: boolean = false;
  twitchObservable: Subscription = new Subscription();
  canStartValue: boolean = false;
  autoStartDone: boolean = false;

  hotkeys: Hotkey[] = [];

  effectTypes = ['SetColor', 'RandomColor', 'Spin', 'Hotkey'];

  spinSpeedValues = [1, 2, 3, 4, 5, 6, 8, 9, 10, 12, 15, 18, 20, 24, 30, 36, 40, 45, 60, 72, 90, 120];

  twitchOptions = ['None', 'CP Redeem', 'Bits', 'Sub', 'Chat'];
  bitOptions = ['Any', 'Single', 'Range'];

  plugin: Plugin;
  currentModel: CurrentModel;

  currentColor: Color = new Color(255, 255, 255);
  currentAplha = 255;
  resetColor: Color = new Color(255, 255, 255);
  touchUi = false;

  retry: number = 0;
  bannerColor: string = '#ffffff';

  @ViewChild('meshInput') meshInput: ElementRef<HTMLInputElement>;

  @ViewChild('modelInput') modelInput: ElementRef<HTMLInputElement>;

  constructor(private vtsApi: VtubeStudioAPIService, private twitch: TwitchService, private appLog: AppLogService) { }

  ngOnInit() {
    this.effect.setColor.forEach(c => {
      if (c === null || c === undefined) {
        c = new Color(255, 255, 255);
      }
      this.colorControl.push(new FormControl(new Color(c.r, c.g, c.b)))
      const color: ThemePalette = 'primary';
      this.colors.push(color);
    });

    this.colorControl.forEach((c, i) => {
      c.valueChanges.subscribe(n => {
        this.effect.setColor[i] = n;
      })
    });
    //VTS 
    this.vtsApi.plugin.subscribe(p => {
      this.plugin = p;
    });

    this.vtsApi.meshes.subscribe(m => {
      this.meshes = m;
      this.meshes = this.meshes.filter(m => !this.effect.selectedMeshes.includes(m));
      this.filteredMeshes = this.meshControl.valueChanges
        .pipe(
          startWith(null),
          map((mesh: string | null) => mesh ? this._filterMeshes(mesh) : this.meshes.slice())
        );

    });

    this.vtsApi.models.subscribe(m => {
      m.forEach(model => this.models.push(model.vtsModelName));

      this.models = this.models.filter(m => !this.effect.modelList.includes(m));
      this.filteredModels = this.modelControl.valueChanges
        .pipe(
          startWith(null),
          map((model: string | null) => model ? this._filterModels(model) : this.models.slice())
        );
    });

    this.vtsApi.hotkeys.subscribe(h => this.hotkeys = h);

    this.twitch.connected.subscribe(c => {
      this.twitchConnected = c;
      if (this.twitchConnected){
        this.twitchIntegration();
      } 
    });

    this.vtsApi.currentModel.subscribe((cm: CurrentModel) => {
      this.currentModel = cm;
      this.autoStartDone = false;
      this._checkCanStart();
    });

    this.canStart.subscribe(s => {
      this.canStartValue = s;
      if (s && this.effect.autoStart && !this.autoStartDone) {
        this.start();
        this.autoStartDone = true;
      }  
    });

    this._running.subscribe(running => this.isRunning = running);
  }

  twitchIntegration() {
    this.twitchObservable.unsubscribe();
    switch (this.effect.twitchType) {
      case 'CP Redeem':
        {
          this.twitchObservable = this.twitch.redeemedReward.subscribe(redeem => {
            if (redeem != null && this.effect.redeemTitle == redeem.rewardTitle) {
              this.processTwitch(redeem.message, redeem.userName);
            }
          });
        }
        break;
      case 'Bits':
        {
          this.twitchObservable = this.twitch.bitsCheered.subscribe(bits => {
            if (bits != null &&
              (this.effect.bitOption === 'Any' ||
                (this.effect.bitOption === 'Single' && this.effect.bits == bits.bits) ||
                (this.effect.bitOption === 'Range' && bits.bits >= this.effect.bits && bits.bits <= this.effect.bitsRange))) {
              this.processTwitch(bits.message, bits.userName);
            }
          });
        }
        break;
      case 'Sub':
        {
          this.twitchObservable = this.twitch.subRecieved.subscribe(sub => {
            if (sub != null &&
              (this.effect.anySub || (this.effect.giftSub === sub.isGift &&
                ((this.effect.primeSub && sub.subPlan == 'Prime')
                  || (this.effect.tier1Sub && sub.subPlan === '1000')
                  || (this.effect.tier2Sub && sub.subPlan === '2000')
                  || (this.effect.tier3Sub && sub.subPlan === '3000')
                )))) {
              this.processTwitch(sub.message.message, sub.gifterName);
            }
          });
        }
        break;
      case 'Chat':
        {
          this.twitchObservable = this.twitch.chatMessage.subscribe(chat => {
            if (chat != null) {
              this.processTwitch(chat.message, chat.user);
            }
          });
        }
        break;
      default:
        {

        }
        break;
    }
  }

  processTwitch(message: string, user: string) {
    if (((this.effect.messageContains && message.toLocaleLowerCase().includes(this.effect.twitchMessage.toLocaleLowerCase()))
      || (this.effect.messageStartsWith && message.toLocaleLowerCase().startsWith(this.effect.twitchMessage.toLocaleLowerCase()))
      || (!this.effect.messageContains && !this.effect.messageStartsWith))
      && ((this.effect.allowUsers && this.effect.allowedUsers.includes(user)) || !this.effect.allowUsers)
      && ((this.effect.blockUsers && !this.effect.blockedUsers.includes(user)) || !this.effect.blockUsers)) {
      if (this.effect.setColorMessage) {
        if (message != undefined || message != null) {
          const result = this.hexToRgb(message);
          if (result != null)
            this.setColor(result.r, result.g, result.b);
        }
      }
      this.start();
      this.stopTwitchTimer();
    }
  }

  addMesh(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our mesh
    if (value && this.meshes.includes(value)) {
      this.effect.selectedMeshes.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.meshControl.setValue(null);
    this.meshes = this.meshes.filter(m => !this.effect.selectedMeshes.includes(m));

  }

  removeMesh(mesh: string): void {
    const index = this.effect.selectedMeshes.indexOf(mesh);

    this.meshes.push(mesh);
    if (index >= 0) {
      this.effect.selectedMeshes.splice(index, 1);
    }
  }

  selectedMesh(event: MatAutocompleteSelectedEvent): void {
    this.effect.selectedMeshes.push(event.option.viewValue);
    this.meshInput.nativeElement.value = '';
    this.meshControl.setValue(null);
    this.meshes = this.meshes.filter(m => !this.effect.selectedMeshes.includes(m));
  }

  addModel(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our mesh
    if (value && this.models.includes(value)) {
      this.effect.modelList.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.modelControl.setValue(null);
    this.models = this.models.filter(m => !this.effect.modelList.includes(m));
    this._checkCanStart();
  }

  removeModel(model: string): void {
    const index = this.effect.modelList.indexOf(model);

    this.models.push(model);
    if (index >= 0) {
      this.effect.modelList.splice(index, 1);
    }
    this._checkCanStart();
  }

  selectedModel(event: MatAutocompleteSelectedEvent): void {
    this.effect.modelList.push(event.option.viewValue);
    this.modelInput.nativeElement.value = '';
    this.modelControl.setValue(null);
    this.models = this.models.filter(m => !this.effect.modelList.includes(m));
    this._checkCanStart();
  }

  addAllowedUser(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    this.effect.allowedUsers.push(value);

    this.removeBlockedUser(value);
    event.chipInput!.clear();
  }

  removeAllowedUser(user: string): void {
    const index = this.effect.allowedUsers.indexOf(user);

    if (index >= 0) {
      this.effect.allowedUsers.splice(index, 1);
    }
  }

  addBlockedUser(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    this.effect.blockedUsers.push(value);

    this.removeAllowedUser(value);
    event.chipInput!.clear();
  }

  removeBlockedUser(user: string): void {
    const index = this.effect.blockedUsers.indexOf(user);

    if (index >= 0) {
      this.effect.blockedUsers.splice(index, 1);
    }
  }

  private _filterMeshes(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.meshes.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterModels(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.models.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _checkCanStart() {
    this._canStart.next((this.effect.allModels && this.currentModel.vtsModelName != 'Not Loaded')
      || this.effect.modelList.includes(this.currentModel.vtsModelName));
  }

  addColor() {
    this.effect.setColor.push(new Color(255, 255, 255));
    this.colors = [];
    this.colorControl = [];

    this.effect.setColor.forEach(c => {
      this.colorControl.push(new FormControl(new Color(c.r, c.g, c.b)));
      const color: ThemePalette = 'primary';
      this.colors.push(color);
    });

    this.colorControl.forEach((c, i) => {
      c.valueChanges.subscribe(n => {
        this.effect.setColor[i] = n;
      })
    });
  }

  setColor(r: number, g: number, b: number) {
    this.effect.setColor = [];
    this.effect.setColor.push(new Color(r, g, b));
    this.colors = [];
    this.colorControl = [];

    this.effect.setColor.forEach(c => {
      this.colorControl.push(new FormControl(new Color(c.r, c.g, c.b)));
      const color: ThemePalette = 'primary';
      this.colors.push(color);
    });

    this.colorControl.forEach((c, i) => {
      c.valueChanges.subscribe(n => {
        this.effect.setColor[i] = n;
      })
    });
  }

  deleteColor(control: FormControl) {
    const index = this.colorControl.indexOf(control);

    this.effect.setColor = this.effect.setColor.filter(c => c.hex !== control.value.hex);

    if (index >= 0) {
      this.colorControl.splice(index, 1);
    }
  }

  async start() {
    if (!this.isRunning && this.canStartValue) {
      try {

        this.appLog.addToLog(this.effect.name + " has started");
        this.colorControl.forEach(c => {
          c.disable();
        })
        if (this.effect.alpha > 255)
          this.effect.alpha = 255;
        if (this.effect.alpha < 0)
          this.effect.alpha = 0;

        if (this.effect.interpolationSteps < 2)
          this.effect.interpolationSteps = 2;
        if (this.effect.interpolationSteps > 1000)
          this.effect.interpolationSteps = 1000;

        this.stop();
        const model = await this.plugin.currentModel();
        const start = await model.position();
        let x = 1;
        let step = 1 / this.effect.interpolationSteps;
        if (this.effect.interpolateColors)
          x = step;
        this.effect.stopSent = false;

        const meshList = ((this.effect.excludeMeshes) ? this.meshes : this.effect.selectedMeshes);

        if (this.effect.delayStart)
          await new Promise(f => setTimeout(f, this.effect.delayStartSec * 1000));
        this._stopped.next(false);
        this._running.next(true);
        this._canStart.next(false);
        switch (this.effect.selectedType) {
          case 'SetColor': {
            
            while (this.isRunning) {
              const mix = ((this.effect.ignoreDisplayLighting) ? 1 : 0.5);
              for (let i = 0; i < this.effect.setColor.length; i++) {
                let c = this.effect.setColor[i];
                this.bannerColor = '#' + c.hex;
                while (x <= 1) {
                  await this._setMeshColor(model, c.r, c.g, c.b, x, mix, this.effect.alpha, meshList);
                  x = x + step;
                }
                if (this.effect.delay <= 0 && !this.effect.interpolateColors)
                  this.effect.delay = 1;
                await new Promise(f => setTimeout(f, this.effect.delay * 1000));
                x = 1;
                if (this.effect.interpolateColors)
                  x = step;
                this.currentColor = c;
              }

              if (this.effect.stopSent || !this.effect.loopEffect)
                this._running.next(false);
            }
          }
            break;
          case 'RandomColor': {
            while (this.isRunning) {
              const mix = ((this.effect.ignoreDisplayLighting) ? 1 : 0.5);
              let c = new Color(this.getRandomInt(0, 255), this.getRandomInt(0, 255), this.getRandomInt(0, 255));

              this.bannerColor = '#' + c.hex;

              while (x <= 1) {
                await this._setMeshColor(model, c.r, c.g, c.b, x, mix, this.effect.alpha, meshList);
                x = x + step;
              }
              if (this.effect.delay <= 0 && !this.effect.interpolateColors)
                this.effect.delay = 1;
              await new Promise(f => setTimeout(f, this.effect.delay * 1000));
              x = 1;
              if (this.effect.interpolateColors)
                x = step;
              this.currentColor = c;

              if (this.effect.stopSent || !this.effect.loopEffect)
                this._running.next(false);
            }

          }
            break;
          case 'Spin': {
            if (this.effect.rotations < 1)
              this.effect.rotations = 1;
            const degree = 360 / this.effect.spinSpeed;
            let i = 0;

            while (this.isRunning && (this.effect.spinForever || i < this.effect.rotations)) {
              let rotation = this.effect.spinSpeed;
              if (this.effect.alternate) {
                rotation = i % 2 == 0 ? this.effect.spinSpeed : this.effect.spinSpeed * -1;
              }
              else {
                rotation = this.effect.clockwise ? this.effect.spinSpeed : this.effect.spinSpeed * -1;
              }
              if (i + 1 === this.effect.rotations && !this.effect.spinForever) {
                const cw = this.effect.clockwise || (this.effect.alternate && i % 2 == 0);
                await this.lastSpin(model, cw);
              }
              else {
                for (let j = 1; j <= degree; j++) {
                  await model.moveBy(.01, { offsetX: 0, offsetY: 0, rotateBy: rotation, sizeChange: 0 });
                }
              }
              if (this.effect.stopSent) {
                this._running.next(false);
              }
              i++;
            }
            if (this.effect.spinForever) {
              const cw = this.effect.clockwise || (this.effect.alternate && i % 2 == 0);
              await this.lastSpin(model, cw);
            }
            start.rotation = 0;
            await model.moveTo(1, start);
            this._running.next(false);
          }
            break;
          case 'Hotkey': {
            const hotkey = this.hotkeys.find(h => h.id === this.effect.hotkeyId);

            this._running.next(true);
            await hotkey.trigger();

            if (hotkey.type === 'ChangeVTSModel') {
              let newModel = null;
              while (newModel === null)
                newModel = await this.plugin.currentModel();
              this.vtsApi.updateCurrentModel(newModel);
              this.twitch.updateBitsCheered(null);
              this.twitch.updateSubRecieved(null);
              this.twitch.updateRedeemedReward(null);
            }
            this._running.next(false);
          }
            break;
          default:
            break;
        }
        this.colorControl.forEach(c => {
          c.enable();
        })
        if (this.effect.resetOnStop)
          await this.resetMeshes();
        this.appLog.addToLog(this.effect.name + " has finished");
        this._stopped.next(true);
        this._checkCanStart();
      }
      catch (e) {
        this.appLog.addToLog("Error " + e.message);
        this._running.next(false);
        this.colorControl.forEach(c => {
          c.enable();
        })
        if (this.effect.resetOnStop)
          await this.resetMeshes();
        this._stopped.next(true);
        if (this.retry < 3) {
          this.retry++;

          await this.start();
        }
      }
    }


  }

  stop() {
    this._running.next(false);
  }

  async resetMeshes() {
    const model = await this.plugin.currentModel();
    const meshList = ((this.effect.excludeMeshes) ? this.meshes : this.effect.selectedMeshes);
    await this._setMeshColor(model, 255, 255, 255, 1, 0, 255, meshList);
  }


  async stopTwitchTimer() {
    await new Promise(f => setTimeout(f, this.effect.twitchStop * 1000));
    if (this.effect.selectedType === 'Hotkey' && this.effect.twitchHotKeyTrigger) {
      const hotkey = this.hotkeys.find(h => h.id === this.effect.hotkeyId);
      await hotkey.trigger();
    }
    if (this.effect.loopEffect || this.effect.spinForever) {
      this.stop();
      this.stopped.subscribe(async (s: boolean) => {
        if (s && this.effect.twitchResetStop)
          await this.resetMeshes();
      });
    }
    else
      if (this.effect.twitchResetStop)
        await this.resetMeshes();
  }

  private async _setMeshColor(model: CurrentModel, nRed: number, nGreen: number, nBlue: number, fraction: number, mix: number, nAlpha?: number, meshes?: string[]) {
    //const mix = 0;
    if (this.effect.allMeshes && !this.effect.excludeMeshes) {
      await model.colorTint(
        {
          r: (nRed - this.currentColor.r) * fraction + this.currentColor.r,
          g: (nGreen - this.currentColor.g) * fraction + this.currentColor.g,
          b: (nBlue - this.currentColor.b) * fraction + this.currentColor.b,
          a: (nAlpha - this.currentAplha) * fraction + this.currentAplha,
          mixWithSceneLightingColor: mix
        }
      );
    }
    else {
      await model.colorTint(
        {
          r: (nRed - this.currentColor.r) * fraction + this.currentColor.r,
          g: (nGreen - this.currentColor.g) * fraction + this.currentColor.g,
          b: (nBlue - this.currentColor.b) * fraction + this.currentColor.b,
          a: (nAlpha - this.currentAplha) * fraction + this.currentAplha,
          mixWithSceneLightingColor: mix
        },
        { nameExact: meshes }
      );
    }
  }

  private getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

  private hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  private async lastSpin(model: CurrentModel, clockwise: boolean) {
    let index = this.spinSpeedValues.findIndex(s => this.effect.spinSpeed === s) - 1;
    let degrees = 0;
    let steps = index < 1 ? 1 : this.spinSpeedValues[index];
    if (clockwise) {
      while (degrees < 360) {
        await model.moveBy(.01, { offsetX: 0, offsetY: 0, rotateBy: steps, sizeChange: 0 });
        degrees += steps;
        if (degrees > 45 && degrees <= 90)
          steps = index - 1 < 1 ? 1 : this.spinSpeedValues[index - 1];
        else if (degrees > 90 && degrees <= 135)
          steps = index - 2 < 1 ? 1 : this.spinSpeedValues[index - 2];
        else if (degrees > 135 && degrees <= 180)
          steps = index - 3 < 1 ? 1 : this.spinSpeedValues[index - 3];
        else if (degrees > 180 && degrees <= 225)
          steps = index - 4 < 1 ? 1 : this.spinSpeedValues[index - 4];
        else if (degrees > 225 && degrees <= 270)
          steps = index - 5 < 1 ? 1 : this.spinSpeedValues[index - 5];
        else if (degrees > 270 && degrees < 360)
          steps = index - 6 < 1 ? 1 : this.spinSpeedValues[index - 6];
      }
    }
    else {
      steps = steps * -1;
      while (degrees > -360 && degrees <= 0) {
        await model.moveBy(.01, { offsetX: 0, offsetY: 0, rotateBy: steps, sizeChange: 0 });
        degrees += steps;
        if (degrees < -45 && degrees >= -90)
          steps = index - 1 < 1 ? 1 : this.spinSpeedValues[index - 1];
        else if (degrees < -90 && degrees >= -135)
          steps = index - 2 < 1 ? 1 : this.spinSpeedValues[index - 2];
        else if (degrees < -135 && degrees >= -180)
          steps = index - 3 < 1 ? 1 : this.spinSpeedValues[index - 3];
        else if (degrees < -180 && degrees >= -225)
          steps = index - 4 < 1 ? 1 : this.spinSpeedValues[index - 4];
        else if (degrees < -225 && degrees >= -270)
          steps = index - 5 < 1 ? 1 : this.spinSpeedValues[index - 5];
        else if (degrees < -270 && degrees < -360)
          steps = index - 6 < 1 ? 1 : this.spinSpeedValues[index - 6];
        else
          steps = steps * -1;

        steps = steps * -1;
      }
    }

  }
}
