import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';

import { ApiClient, WebSocketBus, Plugin, CurrentModel, Hotkey, Model } from 'vtubestudio';

@Injectable({
  providedIn: 'root'
})
export class VtubeStudioAPIService {

  webSocket: WebSocket;
  bus: WebSocketBus;
  apiClient: ApiClient;
  baseModel: CurrentModel
  private _plugin = new BehaviorSubject<Plugin>(null);
  plugin = this._plugin.asObservable();

  private _connected = new BehaviorSubject<boolean>(false);
  connected = this._connected.asObservable();

  private _meshes = new BehaviorSubject<string[]>([]);
  meshes = this._meshes.asObservable();

  private _models = new BehaviorSubject<Model[]>([]);
  models = this._models.asObservable();

  private _hotkeys = new BehaviorSubject<Hotkey[]>([]);
  hotkeys = this._hotkeys.asObservable();

  private _currentModel = new BehaviorSubject<any>({vtsModelName: 'Not Loaded'});
  currentModel = this._currentModel.asObservable();
  

  constructor() {}

  updateConnected(conn: boolean){
    this._connected.next(conn);
  }

  async updatePlugin(plugin: Plugin){
    this._plugin.next(plugin);
    this.updateCurrentModel(await plugin.currentModel());
    this.updateModels(await plugin.models());
  }

  async updateCurrentModel(model: CurrentModel){
    this._currentModel.next(model);
    this.updateMeshes(await model.artMeshNames());
    this.updateHotkeys(await model.hotkeys());
  }

  updateMeshes(meshes: string[]){
    this._meshes.next(meshes);
  }

  updateModels(models: Model[])
  {
    this._models.next(models);
  }

  updateHotkeys(hotkeys: Hotkey[]){
    this._hotkeys.next(hotkeys);
  }

  async authenticate(port: string) {
    
    this.webSocket = new WebSocket('ws://localhost:' + port);
    this.webSocket.onopen = async () => {
      this.bus = new WebSocketBus(this.webSocket);
      this.apiClient = new ApiClient(this.bus);

      let authToken = localStorage.getItem('vtsToken');
      if(authToken !== null)
      {
        let auth = await this.apiClient.authentication({
          pluginName: environment.pluginName,
          pluginDeveloper: 'Prof. Tiberius',
          authenticationToken: authToken
        });

        if(!auth.authenticated)
        {
          let token = await this.apiClient.authenticationToken({
            pluginName: environment.pluginName,
            pluginDeveloper: 'Prof. Tiberius',
            pluginIcon: this.icon()
          });
          localStorage.setItem('vtsToken', token.authenticationToken);
        }
      }
      else
      { 
        let token = await this.apiClient.authenticationToken({
          pluginName: environment.pluginName,
          pluginDeveloper: 'Prof. Tiberius',
          pluginIcon: this.icon()
        });
        localStorage.setItem('vtsToken', token.authenticationToken);
      }

      authToken = localStorage.getItem('vtsToken');

      this.updatePlugin(new Plugin(
        this.apiClient,
        environment.pluginName,
        'Prof. Tiberius',
        this.icon(),
        authToken
      ));
      this.updateConnected(true);
    }
    
  }

  icon(): string {
    return `iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWX
    MAAAsTAAALEwEAmpwYAAA/VklEQVR4nO29d5xd13Xf+137nHPr9Bn0MhhUEiABgiBAgCTY1CxLLq
    IkO1acYkeyJIu2EyXv+eNPYsfJS/LyXsp7L3lykyk5cVEkW8WxJEuyJYpg7wRJAETvmN7ntlP2en
    /sc2fuFEgsAOZJwvp8LoeYmXvnnPNb67fq3lvWjScAlAJh90DCpvGEk82GjFVQoTXUQnuoH8gk5r
    1GZddNw0+tWFY+m0wFrYoqANNfr8u1FRFAECxN8YScbrlJjrXd0u9peDg2+vXhLJ8fzsmIAL7Cso
    oymBOe6TLkEhDAX+hzKx4EFtZU7G+3h/pbviJoTNnkeKVzH4GGdFR7mfJaAXUXcl0Jrq2IoAgGS1
    MyyfnmLRxtv4WMjdcHmqzPW3lvocLvNCV86lJe/mnFl5rKfIzM3G/UDCyp8p5bynZ0m2f/pechoQ
    +RL2SoYj2PF5bcqwOFtbaYTOL0iFQbr8s1kfRZGyyFZIqzzTfqq5278bB4JiLxhdAHfNjq2U/sqt
    rJ9pr+g0hgrgoYK6ACkYFJH9pCPrI3Sr5aMNL2xIRPczNkMoI1gvqGnFawnpGXuvbLSH6FbU7GZ8
    C/rgRXX1LLF5TmeIJLTRvtoc5b8bFkqKGeQT3BC4TOFnhi0idWCfao/aOuKr896QuRkWnL9zp+/V
    8CEIuwbdR+9LbE/j4F+PSRjP7tKz6mINy60kotgUTdBQQaEZtABvJraIrGtTUalshkF+2Z/MjItO
    UreVviQvMWe7hzt/hYyVBDfYMa8ANY0QoPX/T1z54OOFnz2LM6kWVw74gV72xRHkYcd3trPvmbVA
    zcMJrc/d6x5Iu0wqdezujhM0bIIScuGqlmRPeusVKJhVhBRAhsRGQy0lvspr02rO3hoIRebtaFXp
    crKNMBHzQnE1xo3mxf7twtPolkCFPLBz8jrGpXvnY60C896wtZZHxM5FjJ013dVjaU7D2DYg6fL5
    rDxQS87k/+FrkE7h21f9Tise6/HMroa2eM0AwSAAZOXTSiWdHbVlupxUJkZ5QgkUAG86tpiUa1NR
    yRyMs2XPB1uSKSPktBKdgSl5o22UMde1LLD53le+AHwsp25W/P+vrFZ3whA5IFycDosMihCU93LE
    1kRU13DPnmU54qxrfCtnH788uq9t5JX5gVJ2iqBDn46nOefP6Ir0valGxWUI/pmCD2fHl+6X0yWF
    hjm+KJ6zHBlZRp8KEpmeRi0yZ7sGuPGGMlIzO072WEFR3K1075+vknfQgc+NNoqvuMsi+0Rrr55l
    H7G02RYJZUElaV7IdLCFbhwV2h2bbeKhMzmZ0EQB7+9nlPvnLU11WdmgaGM0qAIC933SEjueXXle
    BKSQPtF5NJeos99lDHbRKolYzWpmnfywhrO5Vvnfb1K096QoDUwVcFnYCe1aqfvD2UrChjCMuq9h
    eWlS3e3o/95tZlFf0/Yw9CFUSEfd1W+hOjvb0iBE5zxAMMnDhvpOob3b3GSjUWEgvg3EFsMtJbWE
    dzNK5t4dBMYHhdCd641C1foJBM6cWmzfpK1+3iNQZ8Ke2v6oCvn/L1i095kJkNPlOwZZ3VT+yOSC
    JkvOI+NxfTUfV40iyt6O2438cAkzUo1+CjuyO5ZZNVxhqYIANk4ZvPGvmLI76uaFcyWcGm7iCrFd
    SISxFzK2xzfD1FfFPS8Kya4nH6ij36StdtC4PfCX990tMvPeEJXgP4AOOwudvqr+6LxCbIaBk83M
    8tSlPk7zW5ILs1EeNyPKsYhckKjE/CL94ay46tqkyCJum1ZYAifOt5pwTL2iCbneMODPJS134ZyK
    +xxfh6segNSSP4dpKLTZv0lc69EswB3wuElR0p+E95LuDLMWP5E7BxverH9sRUqzAyBZ4CVhGrTP
    p52qsT67177n/X32P1uh1YA4lDWYxQScA3wv71ifTWPO29IO6PmAZ3cNZILSN6+1or1UiIG7KDyG
    Skr9hNazSq7eHA9RTx9Ujq8xFoTsbpbdqoB7tuxxMrWZ1P+3990tMvPelNR/uN4N+wQfVX7wglDp
    FZ4Ksy5eVpDcfZMXzgpHfPutwDRjPbWb0R1IMkBhGMQDUWVIXbu62cD40O1GOCuhJ4cPKcSJxFd6
    /ReUoQSyADuXqKOHw9Rfxe0hjt2ylH+537xBOdBb6Xgv/NU55+8amFwd/Yo/qJvZGEFWF0SmZZ/p
    TJ014b012D3xXfVo9497zz3Q+Yk09uR33Lhs0Qe0LslEAEaom7sP3rrfRGRnsvzlECcUwQZkT3rH
    FMkFh3Q9NMUFhHczSmbbWh60qwkDQEfMVkkktNG/Vg114xxjLX8ld3wDdOefrFJxYGf8sG1V/ZF0
    lUhdESeKqQKJKQWv6E7hp8GD+pSslvPuLde//bHiBT2M7FV5Ako6zdINNMkKYgtcRVB+5YZ+VizW
    jfpTlKYODkWZEoI7pndSKVOUoQm0D682tpDUe0LRy67g4apdHnJ5P0FXv05a694mPJMtfylb8+6c
    /4/Dngb+5x4Id18O0M+GUvR1t1THcOPYKfVKXiNSHoEe/eu+9+AC/Yjp8Rzj6HRKr03ACJJwspwb
    51ifRGnvbNZQIDJ86JRBmje9baBZWgr9BNSzSubbVBia4rwawMyYG/Xl/u2idevbHTAL6zfP+yPn
    /zetUH74gIq8joVCP4zue3V0ftroHviJ9EdfAR1SPevffd9wDodowHQV64cFCEvNKzQUgMJBFgnD
    uIBUG4o8fKpToTZNx9TLuDcyJxVnT3mssxQXfKBD/ivYMG8JvjcfqLPXqwa58YlKws4PNPe/rFue
    CDS/XWqz54ZySXA7+tNq47Bx8RTyOpeAVkWnM44t17zz0PANsBnBLk4MJLSA1lzRbAF6cEdSYAVN
    i7zsqFULR/rjuYpQQqlXBhd9ASjWlrOCSR+RFUglmp3hT9hW492HWnGKNkqc6z/G+e8vQvLuPzN6
    5XfXBfJHENRqcUzzIL/I7KsL118GHxbSQVU2wEH+CId++99zoFqF+U8cDLCmefFbFG2bDFuYPGwD
    B2ynBXz2ViAoET54zEWRcYzmWCemDYGo3+6DHBPNpfpweXpODrHPA7neX/hSvvzuT5ABOwqUf1V+
    6MJKrB6CTT4JMGfO21cXvr4HcbaN/OndxqUIBZF+dBpgiXXkVCUdZsFvBZiAn2rbNyvmZ04DJMEG
    ZEd6+enx04JlhDazjCj0yK2Ah+PMFAoVtf6loY/JXtLs//4pOe4M8Bfxw2rlP9lTsiiavQSPskUP
    FydFaG7c6hA+In4eXAh3kK0HiR00zwvEiUWHpuBDvXHbivd/RYuRiahd3BWZEkN8ME84pFhW5aox
    FtrQ39cAeGsyx/goHCWvtS111ijEoj+H4grOmEvzndQPtzK3yp5cehMjIls8Cf8nN0VkfsrsHviE
    liqXjFy4EPCypA48WalAkuvoIkvtK9UbA+JCGzA0OXIl6opTFBY2Bo4Ph5kTBAd61y8wTzsoN8N6
    3hqIsJfhiVYI7lDxbW2INd+0UM88Bf1qZ887Svf/FUSvuNAV+d9lOfPxf8spejozpibxk6IGITqc
    4O+BaSyyhA40UbA0FOOP8CUgmhe6tAAHHKBKkSgGOC87UFmMDAiTNG4pzo3rVWygu5g0KDEvwwBY
    ZzLH+wsMa+1LVfMEhWK7Nof01nmurNDfhgmvZ/9c5I4jCt7TdavpdjSXnQ7hp8WMRaqc4P+BaS76
    EAjRdvPAgKLkWMUdZvcS3Aep1gjhJcqBntX6BsfOK8kThj9LbVybxWcjRLCX5IYoIG8IvJJIP5tf
    Zg135Rg+QawPdTn/83pz1n+XOj/UnYUPf5IYxMzga/5OXoqI7pjqEDYjSRqvm+ll+X76MAjTdhTN
    0diFRDpftGQf0ZJqAhO1jfkB1kmJknEDh+VqQSmAWZIDKByw5+GCqGc2h/qLDaHkwtPzfH8ld3wF
    +dSBs7c2l/3FX4fu2utLw7qa623wD+snKf3jL0CMYmr9fy6/I6FKDxZoyBIAsXDopUI2XDjYIGEE
    WzewcKexuzg+zsmODUeRHNobtXW6mEcxpIDUzwA6sEc2h/qLDKHuzcL+rJPMtf0wl/c8abX94FGI
    f1PaqfaIz2G8Cf8nJ0VYZ159AjoCpVk38j4MPrVoBGMR5kCtD7qkg1VrpvEKSuBM7aqzF4acWwPx
    LtbWQCHxA4ds5IxTe6e/X8wLDOBC3hKD9w08bzAz59qevuBcFf1gZfPeHpl55e2PJv3KD64B2RJC
    GMNBR56gHf0vKA7hh+DFH7ZsCHN6wAs5ggDxdeFKlUlZ5tLjBMmcAYqMSupbxvnZVzVaMDF+fMEw
    icOitCAb19jXMH85lgLW0/SGXjOZY/XFhpX+y6W9TMB3/tElfh+/ITDWNcpPillv8rd0YS15SRSZ
    kF/qSfY0llSG8dfPjNWn5d3gQDTN+kgWwRLqVMsO6GWdmBmydgumzcF4v29YorakjKBAaOpTOGO1
    daCZO5M4ZOCZqiibfcShbcUiqjCT4xvsZ4mky/DBaTjs4qb0LJGsAvJFMM5VfrS137RY2ZB/7SNu
    Ubp3z94tOeLDjDt0H1l/dGksxN9Wzd8gd1x/Dj8OYtvy5vQgEabxbj3MGFl0XKVaVn6wwTpIFhNS
    0W3b3ByoWKcV3EOUxw8rRInEP3dc9ngnqxqK0eGL7OFFFQAo3JaEjGhngkquKReFlqfkGrfrNU/a
    Kt+UUb+gWTmEARg0dC1lbFvSfGoKgIOn8Z5fznkdL+aH6FvrD0HuZavhcI3V1pS/fxBQY408bOP7
    4rbenOof0pL8eyUp/uHHpEHPivO9q/nBxZcHXw9xVNVwSjDsWWZfDa34qIUb3rASAvhGXAwxNlqu
    yU4Bd2RZJooC8fF9FiqgQBaBG++aInvof+5KZYBsaFsKbgG3Jxhark5WDXXewYfsx2lHtN2W+auY
    Y5N5/RkMCGhF5Wy34LVb9AKWih4jdT9QoamazEJhArHpoiJyhGLZ6NCGxNc0lZC8mUFKJxcnGZQj
    xJPpmUWAJCk8Wma/OAeZY/nF+pB7vuEgTmWv6KdvjrU75+6RlPyC2Q6q1X/eieSMolGJtqqO1bF+
    0vqQzpzcOPi0Vwln/ZCt/rljenADBHCcyMEmhs9c6fAQpCWAEMxlcmSu5BfWJvJA/5vj5zyIi2pI
    FhxgU+X3vKk0ok+ne2xdI3JkSNSuDl5Pkl97Bz8FG7tHzOTPmtoIqKwWDJJWUAJjKdOpJdxkhuOe
    WgVUKTITHubxtFjDqqF1JfA6IIMZ5EXpaStIh1iQy+RQMbSiGe0PZav3ZU+2mtDYrBUvUKJHhIyh
    DNLtXTF5bc7cCnMmeAU/mr44H+z6eMy4rmBHw3bVL9+L5IKuWFU73lpT7dPvyoWK2D/5Ysf1renA
    tolMaYIFNwdYJySVl/E0ggRK5sbNIUURV2d1s5WzU6ONcdeHD6nIgU0D2NgSEzy9AGcmvSLuKQJM
    Ynl5TxSBjJr9TTLTdxquVmBoorpeoXRQR8YgJ1L19iPEkwYhHRWS8jFiPJrN/3SETFo+w3yVBuqQ
    zl1zKZaUfE0BSPk7MVUeNRtCVGc8v1pa79qDGSo+oWbRi3Vm9tJ3z7rK9ffMKD7PyAr2ed6i/viy
    SswVhpTlfPz7G0MqQ7Bx8RBWpXEHzedAxwWUkDw97DIqWKsm4rmFQJpF4sAoPrIvbHc1LEtE5w9L
    yRimf01lUNgWFDTDBQWEMhLlOMJ2WosEpPtO7kdMtWxrNt4qOS1RCf2AFt3GfOenkLfM9QjxRnfR
    UsvsZkNAIRGc+2y0BhjYxnloFAMZmQkfxKfbnrTmLflzyVmSXaGWF5G3z9lKd//tT8FTtMuendT+
    yNxIZzfH4a8C0r9+nNQ4+hqFxh8OFNxwCXFQUMNC+Fo39rBGv1rtQd1FxMYATGy4DAL+2O5f9NAn
    31qDh34M24g289Y8QTT39mWywXhoVaTTG+IZdUCE1GDnXuphiVmAyaJRFDLokwGqOSFiPqINLw7/
    qLhq+Nl17/2vhyqyhABaMJeRsDHqP5ThnLddIcTVAJ8mKNIa8p+EbwAli3RPnqcV+//LhHo89HgT
    E3zPErd0ZUy5rW9pll+StKvfaWwQOSYN5qtH9ZubIMMCs7KLpiUamk9Nw0wwQYDC5FrI+cXwpF+/
    vmpIgenLhgJApEb12lUmsoG/uaAELVz09H+hgFI6k1C3iSfnWfl8tAUx6a01dTHopZKObc/zfl3L
    8zaf/CAlZcNoKR9MLSexTF1whPLLWggBFLQDxrifaKduWbp3z9i2cWtvxNPaof2xtJXEXGphryfC
    tpqtdvtw8/Llblalh+Xa4wA8wNDJuXwbHvGlFr9e6fwzFBGhgKTJTc2x7cF8vviq8vHjaibekzTp
    ng6097Eiaif+emWHpHhVrN1aGMtWRsCB4uRWuwcBUIfAdqPoBQYbginBs3OlKF8apQiZDIOhv3DZ
    IP0Jas0pGDpUUrHc1K3jiXNVmFMAJRaWAH59ICQjCgRlAj+AGs6lD+6rivfzlnhg+AcZfn/9pdEe
    WSurn96caOUPKyrChdstuHH5NEjdRM7mqBD7yVLOBy0qgEpEpw/IARVat3fwiy+Wkl8DxlMk0R/+
    GtsYQ20EPHRbRptjv42xeM5ANPf2JzIv1jUAsFI+qsvf5cBKwIhYzSUYSJUDg1IZwZEz02Yrg4aS
    iVESIgQVHqpQrS/3f1ax9yBXRlk7Kpw9LTZmVti7K6RRktw1RVMHPBEKcAvg8rO+BvTvv6l097s6
    P9NNVbt071I3siqZRgbFaRx4G/rNxnbx5+/JqAD1dDAWABJljulACs3vMhIN8QEyjjJaEFeDBNEZ
    87bESbZyvBXz3lSSUW/eC2WAYmIIwEse7PWYVcAF1NykhV+MZpo4+f92RwTKCWghykd5uFtIM9+5
    JJU3uFagk5NSqcOu9BxqOtBd27OmHv6kTWdCrDJaEcOs9QZxzPd7T/1WPePMtXgAnYulH143sjqV
    Xm1PatUDJZVpYu2puGn5BEjYQme9XBhysdA8yV6ccskG2CviMiU5PKunpMkPYOSGcMcTHBmYrRwU
    vzU8RTZ0RsHt3bo1KOhAjBGNdU8QP4zhlfP/NiwCsnjZTLrihJukOGeDgkYiAEooavjhVmmMB37y
    Hj/l2dclvlPNbnESK6sUulsxnKiRCLuNp+l/LNE55+6bGGVK/uLiZg3VrX2IlqMDYH/CmTZWXpUu
    rzjYTXwPJTudJp4GUkrbe5FPGQyPgI9OxwoXIYzaSIkft6Z4+VviRNEQNmj5edNVIJRHd1q4jA0l
    Z49qLH7z7j8+IxI1GIUEwBFBzgZRzIPuSb0OVtyvIOdGWXsrxTpa1ZbaHgRh5D9/tCmL4/cNVKsm
    CrLjB9rNcjm4XtqxXjQUcr/PVRX7/4eBrwNQ5wTqap3h2usTPaGPClPn9l6ZK9OQX/WtB+g1zpNP
    B7SWpezcvh1JMixle9+0NCNg+1CojBiDJRdtH2R/fE8js20IOHxAWGjSniE0YCH31geyJ/+pzHd5
    437gdNM9VhLQEJmBbYtFLZ1G51bavSUbDSFEDGm04QUZAoQUoxjFREL4wbPTFqODooEo07iqcAkg
    PNQmUcvnDA49So4cN3Jjx83OiXDhjHNin4AIzCpg31VI9ZAZ+mPn9V6eI19flz5doowPQOE+l/mp
    fDicdEklj13r8v5PJQTQNDlMm0bPyLt0XyGfH14JH5McF3XjLy4jnDpYtM0zwKWgZiWLlauW2lZX
    On1e4WK74g1RDiBCQBElcjArApwTQLtDWrbO2wvGM9nJ8wenzEyPN9hjPnBBWQItAEGsFzrwrnRz
    2dmkAawa8HfJs2qn5sbyzVyuwZPk19/uqpC3rTyBOSqCe1a+Tz58q1cQF1mRUTFNOYYELpudm5g/
    pkkXFL030P9m+00lsz2nt+dkwQW5gcc4CI7wBhAort8JM7E/25bbHc1GUJULEh+FYRC5Wa6HAJ+q
    aE3klDf0kYqyBhjAYgeePq8NVIyPkqW5da9qxK6GxHL1SMVIYAk8YVGZgaE4lFZoM/BjdsUv21u9
    Pp3foMn52x/NVTF/XmkceJ8a9ZwLeAXEsXwJwUUaBlBZx8XASruufn3DoEG4IVvNQdqBH+4b5YQu
    vrK8fEMQG4Ak/BfYyWAQu7brJ84MaEjqyVwQnBeEpO4eiI0TMTHhemhEtThpGaEMUIaR0AAd+Htq
    zqyqJldbOyttlyY6eVqAzjkeHOVYnsXGH5ylFfHz1sRKdS5cun92ZTVzEJGzaqfuyumEp9lW69mp
    hAKA78m0YeJ8KXUBYNfOBauYBGmecOlsFr3xHJt6J7fgIquIdl0j2LKpAI/NN3xPLpoq+PH0xbye
    nbdQq8HPz8voT9q2KGJoTJSejwLC8PGv32eZ/jQwbCNB30Gl5B+j0LcYQMVYWhEY+XFQhgbafV+1
    cn7FqeSLUk1IB/sD2WHSuMfuYJn/I4Ii3M+Pwp2HGj8qv3xjI0AhMlcevzrfudmsnRWRng5uHHJM
    K1lkXfekv3rci1V4BZkjJBpgmqk9M1dxfFSZqcuzy/UoNq5Eo3jeBnCuiD98ZyQ7vl0oiwJLBcLA
    sPncjokUtGSIAcLohrAppx4EcocXoJAU4ZLDDpPpcYzvUb+aN+wyMrPH1gQ8SmDiu9I4ZtHVb+2d
    sj/p/vBjo+3KAEAqUaTFYEnZ4rSsFVTTMD0VgCEjxZbPBhMRVg2h0IaAJ+Lm3eND4Qty1yR0H5k6
    d8ff4lEdqZBr+pBf3H98eyPGsZGIUlgeUbp3z9y2O+UEXIp25iKQ7kUxXlmceVs88IQ6eU8pi7jn
    yr0tltWLMLNt+NbGxxRaEB0BqcvmTkPw1kuW9DrB/YHMvYuKGjqPzGOyL5v78baF8/Is3OJZw4Lv
    whho/eFVOrOP2rSzapMphbIhcL63XdxCGm/NaZwZJFkkVmAFLws6rLb9CZuauUmVVY3qy81md4/E
    i6fa1JfX4AH98fy8q8ZXRcWZqx/PHhjD76WlqFKwLL3e/x1GHlO/8Zfe7zUJ6a2xecLZmMys6fVu
    79JOy/XcQAvU4RHj7sy2hV9Jd2RDJZFvJF+PhdEf/uGwG1UsowLfDqKcMLazy9fXUs54fExQC4Jp
    Kn0F9YoytLp/A0Fvu9Rs2ugSzeX5e0w1YahVXblLVbhUo008YVV96tJuhfvuIp6goymlbtPrQn0f
    UtCSPjSlGU33sxo48eTUetisA64Ohp1V9/r+pvb0MPPCTT4Nf/9twXQBiKPv0F0f9jr+gn9yvPHl
    TWgrQARXjpjCf/+dmMGouWS0pnoPILdySKcUoiHuDDl1/xGCwbbcmlwWF6T9mkymi20/QX1mouKb
    v29SLK4qqfKqCq624T54Pjadu0QFez8sQZj7MXRCim75mCO2+wet/6RAbHhKUZ5c9fC/SFkyn4bU
    A38Gd/qPrP1sPBr7lPNGYGaNWFX/Wfm/SxHHtM9F/eAr/zby3LQTqAnFvi9t9fCWjzlZEJuG1FLO
    /dYZVK+jEFGBtCHj5uaC24vlNjt9Io9Be6JTEZNWqv5ROfJ4urANVJWNIDK26Esk377oBAIQv9Jd
    GHT6QVtjTd61yK/sTWRIbGoStjOXDO08dPekIBpBVoRflPv6n62Y/MBr4R6MvJXGVwiiD65X9h+O
    0PKwEqXUARXjlv5GvHfV2SsfSNCm/fFEvPalXSFjcFOHDa4+SI0dY0Xa2/srbGWKaLkdwyzdrq1X
    zC31cWUQEEwgqs3Ko0ZYQkTOlfUANtRXjpkmF0RIR8ilsE79iU0J61BFY5Ny76uUOBGyQpAu3A//
    Ug+tV/43jVmNcH/FxpfE+qQPrYQ8K/fh9kUWkBMvDV13x5dcBosygByts2Je7d1hWJ4knkpUuG5n
    zdDQj1MbPIiAznVsiPJgOIgMaQb1JdutE1bBop0gihFV4bdMUAAajAkmXo9hWW4QkhI8rXj/sk1b
    QMvAr4ymdU/+ZT88F/szLLNYC+8BXhT/4dLGO61fu1Ez6xRUcnhW3LrGxcpa75BJCBw0OG8ZoQ+A
    33aARfoeS3EnpZXcxMYPEYIAqhqROal8103tIH3ZxTzoyJHhk0kEsxTOCublfly4tybNDoy31u1o
    5lwMvHVH/vI+ldXQHw6zKtBO5R6ef/ORz4rrISyMKZIcPLfYZW37HAXT0JiGMBcnBxSDg21OgG0v
    EEjSgFLZT9Fg00fOvX+SZl8RQgrkLTEiXfxAz9O6osZOHEkIEKkhZtyLWg25ZZxsqQMcozvR4kad
    /dQ/mzXwesXFHw69LoDkD0T/5XKKFSAECeuuSRWHS8LGzpsrK0E6ilOhMhx4ZdX6MxwzGaUPOyTA
    VteDa+ctf6BmXxFMDG0LYSMmnMn9KjZ9xysuMjZqZfW4OtSy3Lm1QkgXNjRl8a9NzAxhLgwLdUn/
    2K+9wrDX5dppkAOPms8I2HlC4gB0eHDMeGhJwobTll23Lr5g/SKuOJEcN4TdRvdAMCVpCpoA1kgQ
    P9rpEskgIoeIFq85KZwl+aDmd8GCmjlyYFgvTHBjZ0up2ui4FyfEQIy0g6saM89tmZiP9qS90VPP
    2nUEUlAELk6LAh5ylhBOs7LGRRTRWgb0oYKAn5YPa9GoWK30RsgkWrCC6OAljrVhEVOlzC39glzs
    BgWZis4OqUCZBHVzZbqhHECXp8zFMk7b/3RujRx9z7r5b116Xx848/KZy9BEV3nSfGDaWaaC2CpQ
    UlV8AFt2mBqH9KyAaz79VTS83LE5qcGk0W/JNXWxZJAWLIFJRcE9PF8pQWMx6MlMVRqAESaM5Cc1
    axFiYqQm/JrSGgCTjzjDJ04dpevwDVKhx/VGkCfOgvG4YrLmQpBEpzxnUBRYAEGa6kBNVQhDYkRC
    YrkcmyWOngtVcAEbAJZPJuW1o72weIwHgoik3jZQv5QMl6jhBGa6KjNVE8XAxw9mX35mtZUpV0yd
    GZg+7fHlRDN07mC2Q9pJhx116X8Zro9K3W71WVRLzUBfyoKAA4BfAyBj9rUDurLaMK5ajhOwoZHw
    08t9inFCJJghsltiiDp3TWm6+2NLgBHToFYdrCsjARIgYl8NBiVrUR03KE1GNIIA1wFSueJBKYH6
    0YAAXjJYjRxvavuJ8QJjLrV3Me4qU2XrMyEzckQHlk8bop5VE3Wp4uUKklIAieqGS9hrhOILKkG1
    80vN+NPKhFElmkuYDF7QXwOtVedPqxqc55j13EUqramTtIhz3q35BZvPa9P+WqXNvrlEVSAAG1Hq
    qG2WyPAIHRWb9aS8Qm6og366WL8hR39cX2xXuA+Ta30ihlpKynokCiorUEO31rCoFxNY5ZF+tcgh
    HUW6y28OIogDEQh5a45hbwN+ItzOTL4BQgQuL09PJigIqP868eQuc6mfXmqy0NswPS1e0KWe5aaA
    5QRYgtUg5FGp9uPnC9IJ3NGBi16mtsXz9hXFm59gqgCsaHqOLKwabBTNIvrRkFkz6rdES8ljiX35
    ZTac+om7WKgLXb3buupQ+tp2xrdzjcrFtW3pFXiVwsoFP1/kYqLZnGbabSIBLBaIxvwwa6uLaySA
    zgQa0s1EozV6DuFSbQXlA3ypVa1mQNpiLnVVtzyvKiuiJLCejZLbQvvbbXr4BvlE13uWuIYGnB0p
    lXF5dGwlQo6dJ1wKAd+VRH6y9AxRDYUAMbYsW7tveQyuIpQFR242D1RZu4r66SBoUczuQNaAXpmz
    TkMpDxkQ2tLvjSCrCiCBv3uvc3jnZdDWn8/I23Kz09QjpBvL5FtTmnkvFhuCyU3eJndw9ZWF5Uqv
    X+QHqviXhkkwqBrYqVRYJiUf4qAnEoMjUIczxALYKOvGVFk2uoiAAxnBpxK4ZKsbCpwyI5lBDwEd
    n395xdXZOMII3yb/sgFBENgQDd0plIaIVsBk6PClRxA6UxLC0qS5pSBWi410Qgn0wR2Hox4drLIg
    6FejB2yc3nY6apMbFQ8JFNHTpTScvC4QHDYNloIkJPu5UdSxKoAYPA/R8Qtt2Xfu5VYoH651qFlV
    uUd/8TYQSoQU+H5YYupWyFyVD0UL9xZUsBQljfbmnPqUT1dQjpSxSK0TiidtFqGYunAEEOJgeFah
    m8tO1n3UOpRrCx00IuHazIwOQocmTADVlahN3LExcoVoEAkQ/9e6izwJVWgmnwnUbKh/53ZQmik+
    6ab1+RkA2Q5rxyYthwcUhmBll82Nyp7vDt9B5RsGLIJJE2RWPEElz+b19lWTwF8LMwNQwTgxBIah
    WujjJREXraVTZ2KVRTLA08dsYwFYtOWWHrUpXNSyxUgV5g7x6Rv/sf3GfrFVSC+uKV+j9//JPKu9
    5nuAiEsKJddedKy3gk4AmPn/EgTum/Cks7VLcssTJWYeYegVgyFONxitG4xOZHTQHqqWBlXGTopO
    IzDf60GwiUG7qsi/YBCnCxT+TVPkNHs/MaP74pBh/VEOgDfu6fIbe9X6cf9FtVguk1Azjr37BP+c
    X/BKOgVcDCj22MKWRUmovKa4NGD50XoZC+P4StSywdeSWMZBb9RwLFaIJMUl3UxSGLWApWtxzs0m
    GhYhWTLvxPT7keL8EtqyzFNkfzdRb49kmPihXKKmzptPK+GyKIQCdxI2T/4i+QO37B5VyN7uCNKE
    Lje6wFa5GtP6bybx8DD9FRoAb3bUp098pERmOD8YWHT3iQuPBGI6CA7lhhKVdd5w/rlFxV8C3aUe
    vTN7Uz+RWUxe0F5Jqh/yT0HVUK3iwWmKoJa1qs3LM+gWoaOBfg4kWRbx71dVk7DIaGd25IZMcaq5
    RAh4EY4V9/RuQ9/8JxbT0zeD2KMPd36j7/ro9Y/uNfQ84IA0AJepZafd+NsYxEhhUdyqNnPD1ypm
    EBSwnu6LbcsNTKWGW29ddMlpZwmM5q38w5CIski7wwzYCNRc48n7bDvemHZIDhkrB/Y8LSZW7BhQ
    hQgG++YuTpi552tsJYLPz8TREb11ilCjoE9AO/9r+J/PMnle5bZtigXi1sXCU0dzVQ4+8u36jya1
    9X/vkfCBOI9rvaw/Ilqr+4M6JmobkIrw17+oUXvOkNLLQC+Tb0bVsSStO+n+n0zwosq5wnSKqSsD
    gFoLos/tKwYgdcOAgXTyj5TPp997NyDVqzyE9td3VgjZmex/9vT3oyGBqKTYI1yD/ZHcrN3Yljgg
    ngArB/r8h/eVHkV7+hsu1+nf6bdYAbXzMKomzco/KRz1v5r8eF975b6E9pfwp6Vlj76/tCKWQRkx
    NqnvDQE55oFSSfVolr8FM3J6xuVRmtiKP/FPzQ5GgNx3V5+Qw1L399dTCeD6WKyMVD6JqNs35kBA
    Ymhe2rVXbfpPrsayJqQIoQTsLvHPD11+6LKBaQiTJ8dEckX8ijB055olPAuXRu8D3vEt7+LuTQOe
    Xot5Szz6FDp9Pt7BWCnErHWmHtrbDlHbBtMzRj6Ac9jas3ALdusvr3toYmFkECwQtEP/Woz9iIuD
    0CLFCFzZtU965PZGDS7XTSKJHAsso5cklZprwWFnN3EFgsBWj0w2JAE6iOz/ysnnkZCNOW+4f2JL
    x8ydfaFG5DpmYYGED+/bcCPnl/xLJmGJ4Q/u6NkexemujnjgZcGhDRGi6VDIAta4VbPyzEH0aqaM
    PotpBDCHCresZTVxICMXR0qv7slogdy6wMR0JzQagg/MdvB/T14na5VMdQZNGfvz0hnxEGYvAb5g
    BDk6WzNqJrSydwp3ouvlzbTaJgNvjGg9oEGE/1lp+C1i7BJtNbulsxNBehUIA/fdaXs/XDKNOPkB
    zUxuH5Xo/upcqGDmWgLCzJq9y5MpbWJtXByFAqiVAFpoBxXAMnREjSVxVhAhhJf55uU9PWpvquTQ
    k/vzWSFc3ISGRY1i5crBj9rwcCBvrc7iCNY17EyHBN2Lnaii9QqaVby6btX8WjLRymJRqV0Cz64Z
    jXaKPIuswFvzoBnq96z0dh3SahUnWDfz5YIzQVoLlJ+OzTHs+8bCDvUqxZH5lz7uCps4YpEbYvt/
    g+jNcMN3ZY2bPcysoW1Y4WdaUHhHhmt1C3d7Bbm6iZLKxus9yyMmF/d8L7NsVy81IrE4khk3fX87
    UTvn72CU+m6vsDzb09A/0XRM5MGb19nZWMKOXQHaLl2YSayUp/fi0t0Vh6TO6iKsE13CVsHvjjoF
    b1no/Duh5hvDZ9kIMVcdu6F+HTT/m8+KpAawP4FrcRpA9ScO5Aq/CdZw2HejP6EzfE3Lo8EVSwFn
    ausHK7UcoROlIRRqqiU6FILUmnjAxSzCgdOaUjrzRlkEiFikIcCF155dVBT//qqCdnz6bb1aXgax
    W3DCxdHyAC2gKHXxP5VOLrg3dF0m7TTSI9t0FE1cvLC0vu5tbBR21Xw/E308vYr6Fc+xigbvlilL
    s/DGvq4Ct4Mm35LU3w0DM+Lx6aDb6mfvnu25SLQ3DypEBLGuxloL8P+cMBnxu6Pb11RSKbO5WWgi
    VOBAvSaqCrScWfDs4UEGKQWAERYt8NeExWhGd7DS/2G14+Y4QabhuYtM2rE7BylbJ5jeqBg0Zs7K
    5DBLQNjp0Q+V0J9JfviKRdUyUgPQPJ5OVg1x3cMpTYjkqvKXnNi6IEV98FNBZW6pYP6P2/DD0bhc
    maWyPlz4Df2gSffs7n+cPGgdsIfgXeucfy4X0JNy6z9IaGgUviKnDp5o0YGBoQeeWC4dkhj96SoR
    SLer5INuMOdBAfNQEivoCHqicSiTAWGo6OGD1w1udLR3yePmakf0gg49I8JLX6EvSsVz66P+FdNy
    SSeKJHTom7Yz+NC7Iw1Ctyasrovh4rGeOGRWYfibeOlnBM2xbHHVxlFzCL9v2U9lW5+x9B9zphoh
    F8pi3/08/6vHDEQPMc8Mvw9j2Wn7k54dhFyHvw4N6Ib3R4fPWIRzQG03v0pwZVmoCnhgxPBUb8In
    QVVTtySDGjmvVBFKklaCmCsaowWBLCEm7JeoCLO8ABHwEVxwJvv9ny09tiahEcOie8Z0sicYL+z8
    eM45Sse5+2wNGTIp8i0Af3RdJOegbwnCPxdg4dsB2VS9ecCa4eAywU7Xue6n0fh55NC1i+0NoEDz
    23APgRUIV37rZ8YFtM/5gQh1ALlUoVti+z7FtjSbLomXHjInqYPi4mXUKOjWBqQmRwVLg0JHJuQO
    TcoHBxWGRwVGSyJJJYps/xlbp5hLjMIYA9m61+5LZY9qywDE/AVBW35XsFdq2y4jehr501oLOZYL
    hP5FTJ6B09VgIjc5ggkP58N63R6LUODK9SFjDX8msTkETovb8EPRvmWH4a7RfhoedT2p8LfhneeZ
    vlgzfF9I/ijo2xINaVECYqQtZTdq+0sn25YvJof2gkruDaxeloWbo76LSrmPcK0t+zONDTjp9fQH
    dvsPzs9ljevi4REmVwQkhidxuSFhLLNacEXkF47Yybdl1ICfausxJ4UGlUAgmkr7DWnZpeu2ZnJV
    8FBVgo1TNG9d6PQ/f39vkLWn4F3rnL8oGtEf1jQlgDY9NpofQl6o4dmKhCW1a5baWVW5ZbepYozc
    2qVRFKibjTQ2ooYUP6N/fgiHQApbNVdcca5b5Nibx3Uyz3rEkkL8rAhFNAUaeA9QEPpwRCqSrsXG
    XFK4q+dvbySnBnj5VgTkwQm0D68mtpvXYp4hWOARZM9RQHfs+Clt/a5Cz/xYXAd5avH9waS9+oUA
    tT8BPm+UcRcevzSjBRhlxGuXVpwu7lyEQoDJRFe6dERyrCRE20PPfQKB9tzSrteWV5k7KsoNKWrk
    ierMLFEaaBdqDP8c8iiKfEKvSOCO/dGIsI+uUnXTG4MSY4dkrkd0ygH98bSZvCWElmYgIvLy927W
    fn0KO2s3zRlPyrGxNcOQVYCHwR1Xt/CdbOBR+aC1Aswu8943Pw6MKW/67bEj5wowM/rCmmftKGVW
    dWxk9PK0+my61i3bXULAzW3Pd8X1mRU1nXBL7n3h7r9L4kGMAXxIgbRqlFUAmhr8zM/F4d9OnOnr
    p3ehlXA7YxqLit7BUuDgvv2RALin75qflKcOSEyKc00E/si6RN0rOCG5VgyX52Dj6qXZULMuW1XD
    UluDIu4HLg3/dxWLdhFu0nRmgpCG0t8NnnfF6Y6/PTVO8du1LLH0sPjUxIkbNunMzzHEqFrDswKL
    SuFdd4tBuAddXlMIJyCFM1t6FzNYRaCGHoPqZUdQFdueq+b2NmLD2hweUoWAPZHGR9qERuWxPxII
    4dEyBYhVJN2LXKivke7uBM2bkDf05gWHcHbdHY1YwJrlAMMCvPnwBQvfej0N0AvidYD1oKQqHgUr
    3nX1vA8mvwY7dZ3n9jJP118OsPPraQLYAxKs9+BTn4ZZHxYSXXBR0t7tC+CLf8fNYZf0z76llfU5
    2ZBrcR6IbfmQYeH3JZKPgwMqby6reRl74kMjagrLnJHRFePyZXUiUIhV2rVDJN6JFzbhX4rDpBn8
    iJKaO3d1vJelCOoH5WciyB9OfX0hyPX606wVtUgIWKPJezfG/G8j/znM8LhxYo8kz7/AbwE9IDlu
    rgo/LonyhHH3YJ98AxkfMvioyNKF4ztLW7zXjEB+ulfX7mK8NCr4WUxWQgCKAQgPGUgV7k1YeRF7
    8EF15y9NJ/VGR8CHpucUoYunVh00wQSj070NfOpEoQpEqQgeFekbMVo3dusOKLUI5np4h9+W7awl
    FtCwclNFeUCd6CAjRegOc3+PyPQff6OZYvzucXHPjP1S0/bZVqGom/c5fl/TdE0j8qhGED+HXLD1
    B59HPK8QOG1pXgZSHb7EDuOyJy/kVk+AJSqQK+Gz0vZISs766xfiZtnR2m+87iNNEEbkQ94zt6D3
    yIrDI+iJx/DTn0beTlv4JLrwp+Rii0u2vINUPf4fT4m+2CSZWgwR1M1YSdq1T8IvrahQWYoF/kdM
    no7rVWMvUUsYEJBvJraI7HtTUcvpJM8CYVYKFUT8TR/hzLt55QLEB7M/z+M8HsaL8Ofhl+/LZEP7
    gtjfYbA75EZyz/wJ8oJx43tKxIgUtN1ngOBPGE4bPChYMiF15Beg8hQxeRqQmoVnU6cDM+BL7geY
    rngecJKkocQmUCRnqRSyeQE88ir30HOfZdOP2UMHpRyBaFfKv7m43TPNlmp4SjQ8q6W1J34JYC1Z
    WgHAq711gJiqKHT8+OCSQDg5dETpSM7t/gWsnVBZigNRzV9ivHBG8iDVwIfFT1no85nz8xO+BrTs
    H/zPM+Lx+T2bSfgv+2W63+5JZYLo2kll8Hv275GVQe/lPlxGOGluVMg98YEWuqCMUO928bC6MXYe
    AEiEEyBXd4ZbbJfaafAfEd2WviE4du7+Jwym1iXSu73cuDdC+jYtfM35q7BK2+dqB5GZx+UkRE9f
    6/D+ShWgYMghCjnB8S3tkTS5Sgf9mQHQDQCifPiPyuH+gv740EGlPEatpAupOdg4/azspFM+W/9e
    zgjTHA5cC/76OwbuNlaf8Pngt4/oiB4kx5tV7effdtCe/fGsvQ+Fyfr5ArgIfKo59XTjxqaFnG9H
    4s3++GjedAzhTdlnTiCXFNqIwLkwPC2CUYu2QYu2QY74XSsFCbEmwsmEDIFt3JZn7Wfdb3+3v1R5
    NtdjHB1KSl+ybw5jNBJXKBoVfEZQfS4A4yMNgncnzSzRO4wLDuDuJpJmiOJ7QtHHyr7uANuIDLgX
    /vR+d19VzAB50t8NnnfZ5/NQ34GsEvOcv/mW3x5QM+L6X9Y48YmpenQcObnKETmVGKIOd2KWt8Bb
    mUFbw3T6t1JsgU4NKrIlNjSs923DG5M4Fhog7UW1dZISd6bG6KmIGRSyIXqvXAMFUCw3SK2J9fQ1
    ttRNvCobeSIr4JBZgL/jzLh+aCUMi7Ct+CAV8tLe+mqV54WfA/5yy/dcXM31/kQ5a+rwjuWnNN0H
    vYnZXcc/PCSpCmiH4RfW1uiphzTHByyuhtaxwTVOOGFNEEMpBfS3P0lgLD16EAC/bz65a/aV7A11
    QQOlrgD54NePHQAgFfCd5xq9WfuylKy7tzfH6d9h/505T2lzM9vf7/d/DrUp8NyxadEkyOzzBBOF
    MnSOxMYCi5NEWsuwMBMjB0UeR02ej+jVY8ESqzAsOM9BW6aQ+HHRO88cDw+yjAQi3d6Wh/04I+v7
    kIn32hocjTaPlVeNutzvL75qZ6yfcC/03S/mJKvchcVwJ3QqpTgnh2TDBVE3auVDFF9Oj5+UwwPC
    hytmR0T7drIFXi+SliSzSmLeHIG2WC76EAC/p86/L89Rtn1/a9mZbu79VTvaaFUj2rP7stkv56wK
    fMTvWmwX/sBxv8usxVgnJJ6bkJpEEJZCZFvL0xRWxkggAGe0WOThq9c31aJ5hmgpjIZKS3sI7W6A
    2niJdRgHmWPwkoes8vOdqfmLH8JLX89hb4oxfS2v4Cqd59O62+v9Hn18uriXVBk4fKo3/2g2/5c6
    WuBJkiXDokUiop6xqUQBqYIBRuWaGieeYHhlkY6xO5WDW6b70VT6ASut0z6zHBYG41beGotkSvOy
    ZYQAEWLPKgev8nFizyNBfc3P4fPBMsXNuvwnt2Jzqd6lUbfX5q+T4qB34IaP9yIjhfmCm67KA0pa
    xPlWDBFNFKrln08Nn5FcOBPpFjk0b3rLWSm8UEEZFkpLe4jubodaeIR2avDVxwhi9R3f+PYO26eV
    29pjTV+6PnfQ4eFpfnN4I/BW+7xeoDN8YyMCZU67Rft/xsPgX/cz88tL+Q1LcHE+OKRcceMfLYny
    s+SpCfbnGLhShUekeFd29I5Cd3p0fRpW1tEaAZTpwU+fQzgRab3DxFIoL6hhwV1CAHu+6U0dwK2x
    RPzGB6GSWYYYCFWrqenzZ25k/y1C3/D5/zeWGhfn4I79pleWCh2v4sn//HysnH31iR5wdVppmgAJ
    deEalUlJ5tAr5rJTMnMFxlJdOCHrlg3Ex7Y+9gwHURd6+tp4gzMUFs/IYu4vd0B6kLEJlxAXXLt7
    GL9tdfprzbAp9+Zk6qx4zlv/s2qz97U+T6+eEc2s81WP7xAz+8lr+QzFKCV0WqsdJzo6ANSpAGhp
    VQ2LNGxc+jRxYIDIcvipwqG717o4sJyuFMsSgyGenNd9MWjmh7beByxaIj3r333TcTA0wv1wrSfv
    7GeZM8LQVxqd7zaao3t6Vbg7ftsvz0lssUeXIFkNTnn3xMaF4ub6nC94ModSUI8o4JylVl3VaBgL
    kp4mRV2L5cxTQtnCKODIicSVPETH2eII0JEhPIQH719yoWHfHuvecexwAzM3zqFm2sX4D2haYi/P
    6zwcKTPFV47x6rP3NjJANza/txWtv302j/+COGlh9B8OsiuIeXycP5l0RqkbLhRkHnK0Elcilirk
    n0UOO0cT1FvCRyfMqNnGe8uSPnGektdNMSjS3EBEe8e+6//wEx3vbpSZ57PuKi/UbLFwd8W7Mb43
    rxsJm/XKsyU+S5NCpEc4s89ZbuYz/kAd8bkToTZNPsoBop3TcsGBNM1oSdl0sRczDaJ3K+4lYg+S
    ZNEU1D2Ti3mtZwRFtnp4hHvLvf/o4HTGl0O56n+rYHZ6d6XkORp8n181+4zBjXj++2+oEbXcAXhY
    o0BnyZdJjju/9dOf5Dmuq9WRHc9jRBygTVUNmwVbA+JLOVoBQKt61OU8QFxssG0xRx3zqVjEkDQ+
    qBYSC9hXU0R+MuRZQsVuSId+/eXQ9IEm53RZ4N8xdqFqGjFT79bMBLR2TBgO9d6RhXvbwrjcMcmX
    w6yfMF5WRjP5/r4DdKPTDsPSQSofRsFtSbFxiWQmHXahWTR187Nb+VPNKb9g42JGKQdPEJ+GnZuK
    +wlvbasLaHA1Iz+SPe/re97QGz/yPbdd3mBVO95iY3zHG5Ac537LL89A2RDKYBn8zy+XlH+4/8iS
    vyNC1p+IDr4M8SIW1XZ+HCyyKVUOleODAs1YTtK1T8JvToAuNlwwMip0ue3t49f0Hq9HhZNC4t4e
    AR757f/h/vkxXdOyjVQCz4xs3tF6GlKHz6uWDh5Vol+LFdVv/OTdFMkWcu+BlUHv0fyvHvOsuXdI
    zqOviXF+O5/RPPvySSoKzf4txBQ0wQK9Ri4fY1Kn5h4RRx8JLIyfoKJNGZ7CCJCE1GBvJrKcSTh7
    27/8l/2G/KtX3O8s30Kt3WIjz0YuCWay2Q6t13q+X9N8SzUz1bL/Lk01Tvc8qJx4SW5XKd9t+AiO
    fcwcVXRGqqrN0C6rvFiGnvIEkDwx0rVCigx87N7x2M9ImcrYjuXWslkHqKCEESYfEZzK142tTi2j
    EPQNxWfU1ZaMnDHzwX6HMLWX7VFXn+7k0RQxNQa6T9KPX5GVQe/WPl6HcMzUvkB66fv5hSLxt7Ph
    Q74eD/FHn6L5WCOPcQOyOTBOJI6RuD922O5QN3JEo0p2zcAodPGPmvT2U0yEBbHhIEFSGf1Ig9uW
    iGsuZp3J8kH7ij2x96MdAXjxlZaK3e23Za3r8lkt6RuUWe1PI9VA58QTn1lNC2yl3Nddp/YzJ9Wr
    kPLcvg8LdEnv6GJWuUIDv9vCWBOFTODwvvXh/LT+xOlNp8JTh2RuR3nw0066cnsOJYZDxjn/HWff
    I3+9ojfW+LsFIzwu8fzPDqqQXAr8K7diX6gRtT2g8XCPg8VL7731zA17xM6gctXwf/TcpMYCice8
    EVi9ZtnXEHzO4d7FplJWiSeSuQJO0dvDLm6falloIiNeVSX948aIazht68+Ww2PZctSWbvbDi9RP
    uWRD94g+vqzaL9elcvQOXxLygnn0gDvut5/luWOhN4ATQvhVe/IfLcN5SCKP58JugdFd6zPpb37Y
    6VMI3XGkr/iQVVpBArgznzx315E3mrP/mbjGTNs50V/akOZcUd6xO5FBntHxB3Pl8I79yR6Pu3xN
    I/jluf3zjJk0lr+4/8cbpo40egq7cYYjwI8m7RS4gLDK03wwTidkSbCmHnMhUvL/raxfRE2gpsWm
    31H98WSi6C0Vj6nu/0PljxNfTafuO36CsIVrjUM6Y/F3nC7asTOVsxOtgv8s4dif7Mllj6J0nX5z
    OzvjqbBnwH/jRdrnW9wndVpV4nOPc8Yn1lw2YhMZC4I9gFpwTlCHYvT8TLoa+dMdK90uqv3hZKEo
    OZgGc7zSdfbjdP+YDX/uu/hafQlzfHCjF0Teq9iYHNnVZWdqJ3rYxltOSGWad35mhM9R79M+XUk6
    nlX0/1rrq4OoFw8RWRyFjWbnZMMF0nAJsIUzVhS5uVznbVe1YnkgOiCThdlE8/tsz/V36Kkbfsf/
    ktfIWqL+RiHllZUS+OuCcrsK7VykQlXfauzICfS33+I3+sHDvQQPtcB/9aiOe7uODccyLWKOu3QG
    LEMYHz3Na6s5c2tVnxE9Jt6/WhIy3mI+ebDM1xGmOCSwF9C8UERn35rQt5+Vglgslx3Bbt9bXzSU
    O0f+DzzvLbVnI91buGUg8M/Qw0LYWXvy7y3LeVvOd2K0nUbZhiQRKYHBeqNbiUk98czMiHizFk7M
    yBbNMzgYqzcl+hLye/fy5vWqcMnzMJeAlIopDJufzyu/9NOPptV9u/bvnXXhqVoNAuPPfnRp79Bu
    Q8VztIFC/FbcrwrXN5s6ovZ/6NYTpCm5Z5q4MFyFpIhIn+nHyoEPMbLaH+o0C8t6PSLU9+vpUTj9
    vrRZ5Flvoz9zPQ1AUvflnEy2C33T8hNrkUoQfGs+ahiYDDKpCzCx9N+f8BRCmrzZoigogAAAAASU
    VORK5CYII=`
  }
}