import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppLogService {

  content: string = "";
  private _log = new BehaviorSubject<string>("");
  log = this._log.asObservable();

  localeId: string;

  constructor(@Inject(LOCALE_ID) private locale: string) 
  {
    this.localeId = locale;
  }

  addToLog(newLine: string)
  {
    const dateTime = formatDate(Date.now(),'long',this.locale);
    this.content += "\n[" + dateTime + "]: " + newLine;
    this._log.next(this.content);
  }
}
