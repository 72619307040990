import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { TwitchService } from '../twitch.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private twitch: TwitchService, private router: Router) {

      // Pulls token from url before the hash fragment is removed

      const routeFragment: Observable<string> = this.activatedRoute.fragment;
      routeFragment.subscribe(fragment => {
        const response = new URLSearchParams(fragment);
        let accessToken = response.get('access_token');
        const idToken = jwt_decode<JSON>(response.get('id_token'));
        if(accessToken != '' && accessToken != undefined)
        {
          twitch.updateAccessToken(accessToken);
          //console.log(accessToken);
        }
        if(idToken != undefined)
        {
          //console.log(idToken);
        }
        this.router.navigate(['/']);
      });
  }

  ngOnInit(): void {
  }

  
}
