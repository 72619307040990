<h1 mat-dialog-title>Import Backup</h1>
<mat-dialog-content class="mat-typography">

  <h2>Only Import Json files exported from Tie-Tools!</h2>
  <h2>This will replace ALL of your effects.</h2>
  <form [formGroup]="myForm">
  
    <div class="form-group">
        <label for="file">File</label>
        <input 
            formControlName="file"
            id="file" 
            type="file" 
            class="form-control"
            (change)="onFileChange($event)">
        <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
            <div *ngIf="f.file.errors?.required">File is required.</div>
        </div>
    </div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button mat-raised-button [mat-dialog-close]="myForm.getRawValue()" cdkFocusInitial>Import</button>
</mat-dialog-actions>
